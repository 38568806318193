import React, { useEffect, useState } from "react";
import { login } from "../Services/Register";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";
import CryptoJS from "crypto-js";

//Admin Login

const SECRET_KEY = "tGh45lP7qR3aS9eC8dN6bM5";

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const encryptedEmail = localStorage.getItem("email");
    if (encryptedEmail) {
      const bytes = CryptoJS.AES.decrypt(encryptedEmail, SECRET_KEY);
      const decryptEmail = bytes.toString(CryptoJS.enc.Utf8);
      setFormData((prev) => ({ ...prev, email: decryptEmail }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await login(formData);
      const token = response.token;

      localStorage.setItem("token", token);

      // console.log("email: ", formData.email);

      // const encryptedEmail = CryptoJS.AES.encrypt(
      //   response.data.email,
      //   SECRET_KEY
      // ).toString();

      // localStorage.setItem("email", encryptedEmail);

      setSuccess("Login Successful!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error to Login:", error);

      setError("Failed to Login. Please check your credentials.");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="login-back w-100  " style={{ height: "100vh" }}>
        <div className="container w-50 rounded-2 ">
          <div className=" " style={{ marginTop: "150px" }}>
            {error && (
              <div className="alert alert-danger mt-4" role="alert">
                {error}
              </div>
            )}
            {success && (
              <div className="alert alert-success mt-" role="alert">
                {success}
              </div>
            )}
            <div className="text-center mt-5 card mb-3 shodow p-2 rounded-pill my-1 border-2">
              <h2>Login</h2>
            </div>

            <form
              className="p-4 my-5   border rounded-3  bg-light "
              onSubmit={handleSubmit}
            >
              <div className="mb-3 row">
                <label htmlFor="username" className="col-sm-2 col-form-label">
                  <strong
                    style={{
                      fontWeight: "850",
                      fontFamily: "monospace",
                      fontSize: "20px",
                    }}
                  >
                    Email:
                  </strong>
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter Email Here"
                    required
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="password" className="col-sm-2 col-form-label">
                  <strong
                    style={{
                      fontWeight: "850",
                      fontFamily: "monospace",
                      fontSize: "20px",
                    }}
                  >
                    Password:
                  </strong>
                </label>
                <div className="col-sm-8">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                    required
                  />
                </div>
                <div className="col-sm-2">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-solid fa-eye"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn rounded-pill btn-dark">
                  Login <i className="fa-solid fa-right-to-bracket"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
