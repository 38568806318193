import React, { useEffect, useState } from "react";
import SideNavigationBar from "./SideNavigationBar";
import "../CSS/PopularCity.css";
import { addPopularCity, getAllPopularCity } from "../Services/PopularCity";
import { Link, useNavigate } from "react-router-dom";
import { getAllState } from "../Services/Location";
import { getAllCategories } from "../Services/CategoriesServices";
import Select from "react-select";

const AdminPopularCity = () => {
  const navigate = useNavigate();
  const [popularCities, setPopularCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Fetch Data From Backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const popularCitiesResponse = await getAllState();

        // Sort cities by district name in alphabetical order
        const sortedCities = popularCitiesResponse.sort((a, b) =>
          a.district.localeCompare(b.district)
        );

        setPopularCities(sortedCities);

        // Fetch categories
        const categoriesResponse = await getAllCategories(token);
        setCategories(categoriesResponse);
      } catch (error) {
        console.error("Error fetching popular cities:", error);
      }
    };
    fetchData();
  }, []);

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption ? selectedOption.value : "");
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const data = {
        locationId: selectedCity,
        categoryId: selectedCategory,
      };

      const response = await addPopularCity(data, token);

      alert("Successfully submitted");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form:");
    }
  };

  const cityOptions = popularCities.map((city) => ({
    value: city.id,
    label: city.district,
  }));

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="myContainer">
        <div>
          <div className="login-box">
            <form onSubmit={handleSubmit}>
              <div className="text-center">
                <h2 className="text-light p-2 mb-4">
                  <i className="fa-solid fa-city"></i> Popular Cities
                </h2>
              </div>

              {/* City Dropdown */}
              <div className="user-box mb-4">
                <Select
                  options={cityOptions}
                  onChange={handleCityChange}
                  value={cityOptions.find(
                    (option) => option.value === selectedCity
                  )}
                  placeholder="Select City"
                  isClearable
                />
              </div>

              {/* Category Dropdown */}
              <div className="user-box">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="text-center">
                <button className="button-17 mt-5" type="submit" role="button">
                  Submit <i className="fa-solid fa-city mx-2"></i>
                </button>
              </div>
              <div className="text-center mb-5">
                <Link
                  to={"/admin/list-pouplar-cities"}
                  state={{ popularCities }}
                >
                  {" "}
                  <i className="fa-solid fa-hand-point-right"></i> List Of
                  Popular Cities
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPopularCity;
