import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCategoryById,
  updateCategory,
} from "../Services/CategoriesServices";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

const AdminUpdateCategory = () => {
  const { id } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    metaDescription: "",
    metaTitle: "",
    image: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const category = await getCategoryById(id, token);
        setCategoryData(category);
        setFormData({
          title: category.title,
          metaDescription: category.metaDescription,
          metaTitle: category.metaTitle,
          image: category.image,
        });
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === "image" ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const token = localStorage.getItem("token");
      await updateCategory(id, formData, token);
      setSuccess(true);
      setError(null); // Clear any previous error
      // Reload the window
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Reload after 3 seconds (adjust the time as needed)
    } catch (error) {
      console.error("Error updating category:", error);
      setError("Error updating category. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="loader-text text-danger">
            <h3> Please wait, Category data is loading...</h3>
          </div>
        </div>
      </div>
    );
  }

  if (!categoryData) {
    return <div>Error: Category not found</div>;
  }

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="container">
        <div className="text-center">
          <div className="card mb-3 shodow p-2 rounded-pill mt-5 border-2">
            <h1>
              Update Category <i class="fa-regular fa-pen-to-square"></i>
            </h1>
            <div className="float-end">
              <button className="btn btn-sm mb-2 mt-2 btn-dark shadow rounded-pill text-light">
                <a
                  href="/admin/categories"
                  style={{ textDecoration: "none" }}
                  className="text-light"
                >
                  <i class="fa-solid fa-left-long"></i> Categories
                </a>
              </button>
            </div>
          </div>
        </div>
        {submitting && (
          <div className="alert alert-info" role="alert">
            Submitting...
          </div>
        )}
        {success && (
          <div className="alert alert-success" role="alert">
            Category updated successfully!
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <form className="card p-4 shadow" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleOnChange}
              className="form-control"
            />
          </div>
          {/*  Meta Description */}
          {/* <div>
            <label htmlFor="metaDescription" className="form-label">
              Meta Description
            </label>
            <textarea
              id="metaDescription"
              name="metaDescription"
              value={formData.metaDescription}
              onChange={handleOnChange}
              className="form-control"
            ></textarea>
          </div> */}

          {/* Meta Title  */}
          {/* <div>
            <label htmlFor="metaTitle" className="form-label">
              Meta Title
            </label>
            <input
              type="text"
              id="metaTitle"
              name="metaTitle"
              value={formData.metaTitle}
              onChange={handleOnChange}
              className="form-control"
            />
          </div> */}
          <br />
          <div>
            <label htmlFor="image" className="form-label">
              Image
            </label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleOnChange}
              className="form-control"
            />
          </div>
          <div className="text-center">
            <div className="mt-2">
              <button
                type="submit"
                className="btn btn-dark rounded-pill shadow"
              >
                Update <i class="fa-regular fa-pen-to-square mx-1"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminUpdateCategory;
