import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../assets/InCallApp 1.png";
import Sidebar from "../Sidebar/Sidebar.jsx";
import Middlebar from "../Middlebar/Middlebar.jsx";
import "./../App.css";
import "./../ResponsiveApp.css";
import "./Navbar.css";
import { AppContext } from "../../Store/Store.jsx";
/* 
    Author: Pritam Mandal, Nihal Sherkar, Chandan Dutta
    Date: 28-05-2024 
    Description: This is Navbar.
*/

const Navbar = () => {
  const { fetchState, fetchData } = useContext(AppContext);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show === true) {
      fetchState();
      fetchData();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    document.body.style.overflowY = isSidebarOpen ? "hidden" : "scroll";
  };

  const handleShow = () => {
    setShow(true);
    document.body.style.overflowY = "hidden";
  };

  const toggleSidebar = () => {
    const newSidebarState = !isSidebarOpen;
    setIsSidebarOpen(newSidebarState);
    document.body.style.overflowY =
      newSidebarState || show ? "hidden" : "scroll";
  };

  return (
    <>
      <div className=" mx-auto w-100 position-fixed top-0 navbar-index">
        <nav className="navbar navbar-light navBar ">
          <div className="container mx-auto">
            <div
              className="navbar-brand d-flex align-items-center justify-content-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/`);
                sessionStorage.clear();
              }}
            >
              <img
                src={Image}
                alt="Logo Of IncallUp"
                className="d-inline-block align-text-top navLogoImg"
              />
              <h2
                className="navLogo"
                placeholder={
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.3)",
                      height: "36px",
                      width: "108px",
                    }}
                  ></div>
                }
              >
                InCallup
              </h2>
            </div>
            <div className="d-flex justify-content-evenly 
            align-items-center gap-4 ms-auto px-2 me-2 me-lg-0">
              <div
                className="rounded-circle text-light px-2 py-1 search"
                style={{ backgroundColor: "#103652" }}
                onClick={handleShow}
                placeholder={<div className="px-3 py-1"></div>}
              >
                <i className="fa-solid fa-magnifying-glass "></i>
              </div>

              <div placeholder={<div className="px-3 py-1"></div>}>
                {isSidebarOpen ? (
                  <i
                    className="fa-solid fa-x hamberger"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleSidebar()}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-bars"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleSidebar()}
                  ></i>
                )}
              </div>
              {isSidebarOpen && (
                <div
                  className="blur-background active"
                  onClick={toggleSidebar}
                ></div>
              )}
              {isSidebarOpen && (
                <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} />
              )}
              {show && <Middlebar show={show} handleClose={handleClose} />}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
