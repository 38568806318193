import React from 'react';
import "./MyApp.css";
import SideNavigationBar from './SideNavigationBar';

const Test = () => {
   

  return (
    <div className="d-flex">
      <SideNavigationBar/>
      <div>
      <h1>This is my File</h1>
      <p> <strong>APP NAME: </strong>  {process.env.REACT_APP_NAME}    </p>
      <p> <strong>ENV: </strong>  {process.env.REACT_APP_ENV}    </p>
      <p> <strong>APP NAME: </strong>  {process.env.REACT_APP_NAME}    </p>

    </div>
    </div>
  );
}

export default Test;
