import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getToken = () => {
  return localStorage.getItem('token');
};

const axiosInstance = () => {
  const token = getToken();
  return axios.create({
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getAllQrCodes = async () => {
  try {
    const response = await axiosInstance().get(`${BASE_URL}/admin/transaction/get-all-qr`);
    return response.data;
  } catch (error) {
    console.error('Error fetching QR codes:', error);
    throw error;
  }
};

export const createQrCode = async (qrCode) => {
  try {
    const response = await axiosInstance().post(`${BASE_URL}/admin/transaction/create-qr-code`, qrCode);
    return response.data;
  } catch (error) {
    console.error('Error creating QR code:', error);
    throw error;
  }
};

export const deleteQrCode = async (id) => {
  try {
    await axiosInstance().delete(`${BASE_URL}/admin/transaction/delete-qr-by-id/${id}`);
  } catch (error) {
    console.error('Error deleting QR code:', error);
    throw error;
  }
};

export const setActiveQrCode = async (id) => {
  try {
    const response = await axiosInstance().put(`${BASE_URL}/admin/transaction/set-active-qr-code/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error setting active QR code:', error);
    throw error;
  }
};
