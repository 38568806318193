import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Image from "../../assets/InCallApp.png";
import "./Middlebar.css";
import {
  getDistrictsByState,
} from "../../Services/SearchAdService";
import { AppContext } from "../../Store/Store";

function Middlebar({ show, handleClose }) {
  const [loading, setLoading] = useState();
  const [searchCat, setSearchCat] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const navigate = useNavigate();
  const { state, fetchDataByStateLocation } = useContext(AppContext);

  const handleSearchButtonClick = async () => {
    if (selectedState && !selectedDistrict) {
      alert("Please select a location");
      return;
    }
    let selectedCategoryObj;
    if (!selectedCategory) {
      selectedCategoryObj = categories[0]; // Select the first category as default
    } else {
      selectedCategoryObj = categories.find(
        (category) => category.title === selectedCategory
      );
    }
    const selectedDistrictObj = districts.find(
      (district) => district.district === selectedDistrict
    );
    const requestData = {
      categoryId: selectedCategoryObj?.title?.replaceAll(" ","-"),
      locationId: selectedDistrictObj?.district?.replaceAll(" ", "-"),
      keyWord: keyword,
      districtName: selectedDistrict, // Add this line to pass the district name
    };
    try {
      const response = await fetchDataByStateLocation(
        requestData.categoryId,
        requestData.locationId, 
        requestData.keyWord
      );
      setSearchCat(prev => prev = response);
      const districtName = selectedDistrict.replaceAll(" ", "-").toLowerCase();
      const CategoryName = selectedCategoryObj.title
        .replaceAll(" ", "-")
        .toLowerCase();
      if(districtName){
        navigate(`/${CategoryName}/${districtName}`, {
          state: {
            SearchData: response,
            districtName: selectedDistrict,
            keyWord: keyword,
          },
        });
      }else{
        navigate(`/${CategoryName}`, {
          state: {
            SearchData: response,
            districtName: selectedDistrict,
            keyWord: keyword,
          },
        });
      }
      sessionStorage.clear();
      handleClose();
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    if (state) {
      setCategories(state.categories);
      try {
        const uniqueStates = state?.states?.filter(
          (state, index, self) =>
            self.findIndex((s) => s.state === state.state) === index
        );
        const sortedUniqueStates = uniqueStates?.sort((a, b) => {
          const nameA = a.state.toLowerCase();
          const nameB = b.state.toLowerCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });
        setStates(sortedUniqueStates);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoading(state.loading);
      }
    }
  }, [state, searchCat]);

  const handleStateChange = async (event) => {
    const selectedStateName = event.target.value;
    setSelectedState(selectedStateName);
    try {
      const districtsData = await getDistrictsByState(selectedStateName);
      // Sort districts alphabetically
      districtsData.sort((a, b) => a.district.localeCompare(b.district));
      setDistricts(districtsData);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
    setIsLocationSelected(false); // Reset the location selection flag
  };
  

  const handleClear = () => {
    setSelectedState("");
    setSelectedCategory("");
    setSelectedDistrict("");
    setKeyword("");
    setIsLocationSelected(false);
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      {show && (
        <>
          <div className="overlay" onClick={handleClose}></div>
          <div className="centered-div">
            <div
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              className="d-flex w-100 justify-content-end 
              align-items-end top-0 right-0 mt-2 mx-2 searchBarXmark"
            >
              <i className="fa-solid fa-xmark pe-3"></i>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100 h-25 pb-3 pt-1">
              <Link
                className="navbar-brand d-flex align-items-center justify-content-center"
                to={`/`}
              >
                <img
                  src={Image}
                  alt="Logo Of IncallUp"
                  className="d-inline-block align-text-top navLogoImg"
                />
                <h2 className="navLogo">InCallup</h2>
              </Link>
            </div>
            <div className="w-100 gap-4 h-75">
              <select
                className="form-select mb-4"
                aria-label="Default select example"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                {categories.length > 0 ? (
                  <option value={categories[0].title}>
                    {categories[0].title}
                  </option>
                ) : (
                  <option value="">No categories available</option>
                )}
                {categories.slice(1).map((category, index) => (
                  <option key={category.id} value={category.title}>
                    {category.title}
                  </option>
                ))}
              </select>
              <select
                className="form-select mb-4"
                aria-label="Default select example"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {states &&
                  states.map((state) => (
                    <option key={state.id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
              <select
                className="form-select mb-4"
                aria-label="Default select example"
                value={selectedDistrict}
                onChange={(e) => setSelectedDistrict(e.target.value)}
                disabled={!selectedState} // Disable the location selection if no state is chosen
              >
                <option value="">Select Location</option>
                {districts.map((district) => (
                  <option key={district.id} value={district.district}>
                    {district.district}
                  </option>
                ))}
              </select>
              <textarea
                className="w-100 py-1 px-2 d-flex align-items-center rounded select-container"
                placeholder="Search Keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              ></textarea>
              <div className="d-flex flex-row justify-content-between align-items-center w-100 mt-2">
                <button
                  className="btn btn-secondary"
                  onClick={handleClear}
                  style={{ width: "45%", backgroundColor: "#103652" }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-2"
                  style={{ width: "45%", backgroundColor: "#103652" }}
                  onClick={handleSearchButtonClick}
                  disabled={
                    selectedState && !selectedDistrict && selectedCategory
                  } // Disable the search button if no location is chosen
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                  Search
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Middlebar;
