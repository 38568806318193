import React, { useState } from "react";
import { register } from "../Services/Register";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

const AdminRegister = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    fullName: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
     

    try {
      // const data = {
      //   email: formData.email,
      //   password: formData.password,
      //   fullName: formData.fullName,
      // };

      //  // This will log the type of 'data'
      //  // This will log the data itself
      await register(formData);
       

      setSuccess("Register Successful!");
    } catch (error) {
      console.error("Error to Registration:", error);
      setError("Failed to Register. Please check your credentials.");
    }
  };

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="container  mt-5">
        <div className="text-center card mb-3 shodow p-2 rounded-pill my-1 border-2">
          <h2>Register</h2>
        </div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {success && (
          <div className="alert alert-success" role="alert">
            {success}
          </div>
        )}
        <form className="p-4 my-5 card shadow" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter Name here"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Email Here"
              required
            />
          </div>
          <div className="mb-3 row align-items-center">
            <div className="col">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter password"
                required
              />
            </div>
            <div className="col-auto    " style={{marginTop:"29px"}}   >
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <i className="fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="fa-solid fa-eye"></i>
                )}
              </button>
            </div>
          </div>
          
          <div className="text-center">
            <button type="submit" className="btn btn-dark rounded-pill">
              Register <i className="fa-regular fa-pen-to-square"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminRegister;
