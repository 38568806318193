import React, { useState, useEffect } from "react";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

// import { useParams } from "react-router-dom";

/**
 *  Auther    : Nihal Sherkar
 *  Date      : 29 may 2024
 *  Description : It contain all the title etc part
 */

const EditPage = () => {
  // const { index } = useParams();

  // const location = useLocation();

  // Initialize state to hold edited data
  const [editedData, setEditedData] = useState({
    date: "",
    title: "",
    mainPicture: [],
    userName: "",
    city: "",
    verifiedEmail: false,
  });

  // Function to handle edits
  const handleEdit = (event, fieldName) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setEditedData({ ...editedData, [fieldName]: value });
  };

  // Function to save edited data
  const saveData = () => {
     
    // Implement logic to save editedData to backend or perform other actions
    // Redirect back to the listing page after saving
    window.location.href = "/admin/adlisting";
  };

  useEffect(() => {
    // Extract data from URL parameters
    const queryParams = new URLSearchParams(window.location.search);
    const data = {};
    for (let param of queryParams.entries()) {
      if (param[0] === "mainPicture") {
        // Convert mainPicture to an array if it's not already
        data[param[0]] = param[1].split(","); // Assuming the mainPicture string is comma-separated
      } else {
        data[param[0]] = decodeURIComponent(param[1]);
      }
    }
    setEditedData(data);
  }, []);

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div>
        <div>
          <a
            href="/adlisting"
            className="btn btn-danger btn-sm mt-3"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <i class="fa-solid fa-arrow-left mx-2"></i>Back
          </a>
        </div>
        <div className="text-center mt-5">
          <h1 className="text-uppercase">
            {" "}
            <i class="fa-solid fa-user-pen"></i> Edit Page{" "}
          </h1>
        </div>

        <div className="card mt-5 border border-1 p-3 rounded rounded-4 shadow">
          <table className="table card-body">
            <thead>
              <tr className="text-center">
                <th scope="col">Date</th>
                <th scope="col">Title</th>
                <th scope="col">Main Picture</th>
                <th scope="col">User Name</th>
                <th scope="col">City</th>
                <th scope="col">Verified Email</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Render input fields for editing */}
              <tr>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    value={editedData.date}
                    onChange={(e) => handleEdit(e, "date")}
                  />
                </td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    value={editedData.title}
                    onChange={(e) => handleEdit(e, "title")}
                  />
                </td>
                {/* Render main picture */}
                <td>
                  {editedData.mainPicture.map((pic, picIndex) => (
                    <img
                      key={picIndex}
                      src={pic}
                      alt={`main-${picIndex}`}
                      className="img-thumbnail"
                      style={{
                        width: "50px",
                        marginRight: "5px",
                        height: "50px",
                      }}
                    />
                  ))}
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={editedData.userName}
                    onChange={(e) => handleEdit(e, "userName")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={editedData.city}
                    onChange={(e) => handleEdit(e, "city")}
                  />
                </td>
                <td className="text-center ">
                  <input
                    className="mt-2"
                    type="checkbox"
                    checked={editedData.verifiedEmail}
                    onChange={(e) => handleEdit(e, "verifiedEmail")}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-primary shadow btn-sm"
                    onClick={saveData}
                  >
                    Save
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EditPage;
