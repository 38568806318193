import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;


const getAllPost = async (token) => {

    try {
        const response = await fetch(`${BASE_URL}/admin/post/get-all-posts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (!response.ok) {
            throw new Error(`Failed to fetch categories: ${response.statusText}`);
          }
          const data = await response.json();
           
          return data;

    } catch (error) {
        console.error("Error fetching the categories:", error);
        throw error;
    }

}


const deletePost = async (postId, token) => {
    try {
        const response = await axios.delete(`${BASE_URL}/admin/post/delete/${postId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Something went wrong.....");
        throw error;
    }
}



export { getAllPost, deletePost };

