import React, { useState } from "react";
import SideNavigationBar from "./SideNavigationBar";
import { sendLocation } from "../Services/Location"; // Importing the sendLocation function
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";

const AdminLocation = () => {
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    metaTitle: "",
    metaDescription: "",
    locationDescription: "", //* This will store the content from React Quill
    canonicalLink: "",
    h1Tag: "",
    h2Tag: "",
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      if (name === "image" && files.length > 0) {
        return {
          ...prevFormData,
          image: files[0],
        };
      } else {
        return {
          ...prevFormData,
          [name]: value,
        };
      }
    });
  };

  const handleDescriptionChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      locationDescription: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData object
    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    console.table("Data after appending: ", data);
    try {
      // Sending form data to service
      await sendLocation(data);

      setFormData({
        state: "",
        district: "",
        metaTitle: "",
        metaDescription: "",
        locationDescription: "",
        canonicalLink: "",
        h1Tag: "",
        h2Tag: "",
      });
      // alert("Location data submitted successfully!");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Location data submitted successfully!",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      console.error("Error sending location data:", error);
      alert("Failed to submit location data. Please try again later.");
    }
  };

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="   w-100" style={{ background: "#333" }}>
        <div className="    container">
          <div className=" ">
            <div className="mt-5 mb-2">
              <Link
                to={"/admin/list-location"}
                className="  text-light"
                style={{ textDecoration: "none" }}
              >
                <i class="fa-solid fa-list-ul"></i> List Location
                <i class="fa-regular fa-hand-point-right mx-2"></i>
              </Link>
            </div>
          </div>
          <div>
            <form
              className="card shadow px-5 mb-4  rounded-4 border-2"
              onSubmit={handleSubmit}
            >
              <div className="pt-3">
                <label htmlFor="state" className="form-label">
                  <strong>State</strong>
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  className="form-control mb-2"
                  placeholder="Enter state"
                  value={formData.state}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label htmlFor="city" className="form-label">
                  <strong>District</strong>
                </label>
                <input
                  type="text"
                  name="district"
                  id="district"
                  className="form-control mb-2"
                  placeholder="Enter District"
                  value={formData.district}
                  onChange={handleInputChange}
                />
              </div>

              {/* //! Meta Title  */}
              <div className="mb-3">
                <label htmlFor="metaTitle" className="form-label">
                  <strong>Meta Title:</strong>
                </label>
                <input
                  type="text"
                  name="metaTitle"
                  id="metaTitle"
                  className="form-control"
                  placeholder="Enter Meta Title"
                  value={formData.metaTitle}
                  onChange={handleInputChange}
                />
              </div>

              {/* //! META DESCRIPTION */}
              <div className="mb-3">
                <label htmlFor="metaDescription" className="form-label">
                  <strong>Meta Description:</strong>
                </label>
                <textarea
                  type="text"
                  name="metaDescription"
                  id="metaDescription"
                  className="form-control"
                  placeholder="Enter Meta Description"
                  value={formData.metaDescription}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label htmlFor="canonicalLink" className="form-label">
                  <strong>Canonical Link </strong>
                </label>
                <input
                  type="text"
                  name="canonicalLink"
                  id="canonicalLink"
                  className="form-control mb-2"
                  placeholder="Enter canonicalLink link"
                  value={formData.canonicalLink}
                  onChange={handleInputChange}
                />
              </div>

              {/* //! LOCATION DESCRIPTION */}
              <div className="mb-3">
                <label
                  htmlFor="locationDescription"
                  className=" mt-1 form-label"
                >
                  <strong>Location Description:</strong>
                </label>
                {/* <textarea
                type="text"
                name="locationDescription"
                id="locationDescription"
                className="form-control"
                placeholder="Enter Location Description"
                value={formData.locationDescription}
                onChange={handleInputChange}
              /> */}
                <ReactQuill
                  theme="snow"
                  value={formData.locationDescription}
                  onChange={handleDescriptionChange}
                  placeholder="Enter Location Description Here"
                />
              </div>

              <div>
                <label htmlFor="h1Tag" className="form-label">
                  <strong> H1 Tag</strong>
                </label>
                <input
                  type="text"
                  name="h1Tag"
                  id="h1Tag"
                  className="form-control"
                  placeholder="Enter H1 Tag   "
                  value={formData.h1Tag}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label htmlFor="h2Tag" className="form-label">
                  <strong>H2 Tag</strong>
                </label>
                <input
                  type="text"
                  name="h2Tag"
                  id="h2Tag"
                  className="form-control"
                  placeholder="Enter H2 Tag  "
                  value={formData.h2Tag}
                  onChange={handleInputChange}
                />
              </div>

              <div className="text-center my-2 pb-2">
                <button
                  className="btn btn-dark rounded-pill mx-2"
                  type="submit"
                >
                  Submit <i class="fa-solid fa-paper-plane"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLocation;
