import React from "react";

const SpaForm = () => {
  return (
    <div className="container " style={{ paddingTop: "90px" }}>
      <div className="row">
        <div>
          <form action="">
            {/* //?   Category */}
            {/* //* Title */}
            {/* //* Description */}
            {/* //* Contact */}
            {/* //* Address */}
            {/* //* State */}
            {/* //* City */}
            {/* //* Service */}
            {/* //* Photos */}
            {/* //! category */}
            <div class="mb-3">
              <label for="disabledSelect" class="form-label">
                Category
              </label>
              <select id="disabledSelect" class="form-select">
                <option>Choose</option>
              </select>
            </div>
            {/* //! Title */}
            <div class="mb-3">
              <label for="title" class="form-label">
                Title
              </label>
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                aria-describedby="title"
                placeholder="Enter Title"
              />
            </div>

            {/* //! Description */}
            <div class="mb-3">
              <label for="title" class="form-label">
                Description
              </label>
              <textarea
                type="text"
                cols={10}
                rows={3}
                class="form-control"
                id="description"
                name="description"
                aria-describedby="description"
                placeholder="Enter Description"
              />
            </div>

            {/* //! Address */}
            <div class="mb-3">
              <label for="title" class="form-label">
                Address
              </label>
              <textarea
                class="form-control"
                id="address"
                name="address"
                aria-describedby="address"
                placeholder="Enter Address"
              />
            </div>

            {/* //! state, City and Contact */}
            <div className="d-flex">
              {/* State */}
              <div class="mb-3">
                <label for="disabledSelect" class="form-label">
                  State
                </label>
                <select id="disabledSelect" class="form-select">
                  <option>Choose State</option>
                </select>
              </div>
              <div className=" mx-5">
                {/* City */}
                <div class="mb-3">
                  <label for="state" class="form-label">
                    City
                  </label>
                  <select id="city" class="form-select">
                    <option>Choose City</option>
                  </select>
                </div>
              </div>
              {/* //! Contant */}
              <div class="mb-3">
                <label for="title" class="form-label">
                  Contant
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="contact"
                  name="contact"
                  aria-describedby="contact"
                  placeholder="Enter Contact"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SpaForm;
