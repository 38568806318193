import React, { createContext, useReducer } from "react";
import axios from "axios";
import reducer from "./Reducer";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AppContext = createContext();

const initialState = {
  categories: [],
  states: [],
  categoryPosts: {},
  postByLocation: [],
  post: [],
  popularLocations: [],
  allStates: [],
  loading: true,
  error: null,
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const categoriesResponse = await axios.get(
        `${BASE_URL}/dashboard/get-all-categories`
      );
      dispatch({ type: "SET_CATEGORIES", payload: categoriesResponse.data });
      return categoriesResponse.data;
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
      console.error("Error fetching data:", error.message);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const fetchState = async () => {
    try {
      const statesResponse = await axios.get(
        `${BASE_URL}/dashboard/get-all-state`
      );
      dispatch({ type: "SET_STATES", payload: statesResponse.data });
    } catch (error) {
      console.log("error message: " + error.message);
    }
  };

  const fetchPost = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/dashboard/get-post/${id}`);
      dispatch({ type: "SET_POST", payload: response.data, id });
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
      console.error("Error fetching the Post Data:", error);
    }
  };

  const fetchAllState = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/dashboard/get-states`);
      dispatch({ type: "SET_ALL_STATES", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
    }
  };

  const fetchDataByStateLocation = async (categorieName, location, keyWord) => {
    try {
      // Encode the URL parameters
      const encodedCategoryName = encodeURIComponent(categorieName);
      const encodedLocationName = encodeURIComponent(location);
      // const encodedKeyword = keyWord ? encodeURIComponent(keyWord) : null;
  
      let url
      if (keyWord) {
        url = `${BASE_URL}/dashboard/post-by-location-and-category-name?categoryName=${encodedCategoryName}&locationName=${encodedLocationName}&keyword=${keyWord}`;
      }else{
        url = `${BASE_URL}/dashboard/post-by-location-and-category-name?categoryName=${encodedCategoryName}&locationName=${encodedLocationName}`
      }
  
      const response = await axios.post(url);
      
      dispatch({ type: "SET_DATA_BY_LOCATION&CAT", payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
      return error;
    }
  };
  

  return (
    <AppContext.Provider
      value={{
        state,
        fetchAllState,
        fetchPost,
        fetchState,
        fetchDataByStateLocation,
        fetchData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
