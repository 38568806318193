 
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllCategories = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/admin/categories/get-all-categories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch categories: ${response.statusText}`);
    }

    const data = await response.json();
     
    return data;
  } catch (error) {
    console.error("Error fetching the categories:", error);
    throw error;
  }
};


const getCategoryById=async(id,token)=>{
  try {
    const response=  await axios.get(`${BASE_URL}/admin/categories/get-category/${id}`,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
    })

    return response.data;
  } catch (error) {
    console.error("Error fetching category by id:", error);
    throw error;
  }
}





  const createCategory = async (formData, token) => {
  try {
    const response = await fetch(`${BASE_URL}/admin/categories/create-category`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Failed to create category: ${response.statusText}`);
    }

    const data = await response.json();
     
    return data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};


const deleteCategory=async(id,token)=>{
  try {
    const response= await axios.delete(`${BASE_URL}/admin/categories/delete-category/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data;


  } catch (error) {
      console.error("Something went wrong.....");
      throw error;
  }
}


const updateCategory = async (id, formDataToSend, token) => {
  try {
    const formData = new FormData(); // Create FormData object

    // Append each field and its value to the FormData object
    Object.keys(formDataToSend).forEach(key => {
      formData.append(key, formDataToSend[key]);
    });

    const response = await fetch(`${BASE_URL}/admin/categories/update-category/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData  // Pass the FormData object as the body
    });

    if (!response.ok) {
      // Throw error with more detailed message if possible
      throw new Error(`Failed to update category: ${response.statusText}`);
    }

    // Parse response as JSON assuming backend returns updated category data
    const data = await response.json();
     
    return data;
  } catch (error) {
    // Log error and re-throw it for potential handling in the component
    console.error("Error updating category:", error);
    throw error;
  }
};




export { getAllCategories,getCategoryById,createCategory,deleteCategory,updateCategory };
