import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import Image from "../../assets/InCallApp 1.png";
import Sidebar from "../Sidebar/Sidebar.jsx";

const Navbar2 = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    const newSidebarState = !isSidebarOpen;
    setIsSidebarOpen(newSidebarState);
    document.body.style.overflowY =
      newSidebarState ? "hidden" : "scroll";
  };

  return (
    <div className=" mx-auto w-100 position-fixed top-0 navbar-index">
      <nav className="navbar navbar-light navBar ">
        <div className="container mx-auto">
          <div
            class="navbar-brand d-flex align-items-center justify-content-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/`);
              sessionStorage.clear();
            }}
          >
            <img
              src={Image}
              alt="Logo Of IncallUp"
              className="d-inline-block align-text-top navLogoImg"
            />
            <h2
              className="navLogo"
              placeholder={
                <div
                  style={{
                    backgroundColor: "rgba(255,255,255,0.3)",
                    height: "36px",
                    width: "108px",
                  }}
                ></div>
              }
            >
              InCallup
            </h2>
          </div>
          <div className="d-flex justify-content-evenly align-items-center gap-4 ms-auto px-2 me-2 me-lg-0">
            {isSidebarOpen ? (
              <i
                className="fa-solid fa-x hamberger"
                style={{ cursor: "pointer" }}
                onClick={() => toggleSidebar()}
              ></i>
            ) : (
              <i
                className="fa-solid fa-bars"
                style={{ cursor: "pointer" }}
                onClick={() => toggleSidebar()}
              ></i>
            )}
            {isSidebarOpen && (
              <div
                className="blur-background active"
                onClick={toggleSidebar}
              ></div>
            )}
            {isSidebarOpen && (
              <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} />
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar2;
