import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllPopularCity = async (token) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/admin/popular-locations/get-popular-location`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
     
     

    return data;
  } catch (error) {
    console.error("Error fetching popular cities: ", error);
    throw error;
  }
};

const addPopularCity = async (fromData, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/popular-locations/add`,
      fromData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response) {
      throw new Error("Failed to add popular city");
    }

    const data = response.data;
     
    return data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

const deletePopularCityById = async (id, token) => {
   
   
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/popular-locations/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Something went wrong.....");
    throw error;
  }
};

export { getAllPopularCity, addPopularCity, deletePopularCityById };
