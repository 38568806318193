import React, { useState, useEffect } from "react";
import {
  deleteCategory,
  getAllCategories,
} from "../Services/CategoriesServices";

import "../CSS/Categories.css";
import "../CSS/CreateCategory.css";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

const AdminCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingId, setDeletingId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(5);

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategory = categories.slice(
    indexOfFirstCategory,
    indexOfLastCategory
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const categoryData = await getAllCategories(token);
        setCategories(categoryData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleCreateCategory = () => {
    window.location.href = "/admin/create-category";
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    setDeletingId(id);

    const newCategories = categories.filter((category) => category.id !== id);
    setCategories(newCategories);

    try {
      await deleteCategory(id, token);
    } catch (error) {
      setCategories(categories);
      console.error("Error deleting category:", error);
      alert("Error deleting category.");
    } finally {
      setDeletingId(null);
    }
  };

  const handleUpdate = (id) => {
    try {
      window.location.href = `/admin/updateCategory/${id}`;
    } catch (error) {}
  };

  return (
    <div className="d-flex   " style={{ background: "#333" }}>
      <SideNavigationBar />
      <div className="container d-flex justify-content-center mt-5">
        <div className="col-md-10">
          <div className="text-center">
            <div className="card mb-3 shadow p-2 rounded-pill my-1 border-2">
              <h1>
                <i className="fa-solid fa-list"></i> Categories
              </h1>
            </div>
            <button
              className="btn btn-dark rounded-pill mt-2 shadow btn-wrap"
              onClick={handleCreateCategory}
            >
              Create Category <i className="fa-solid fa-plus mx-2 "></i>
            </button>
          </div>

          {loading ? (
            <div className="loader-container">
              <div
                className="cssload-main"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div className="cssload-heart ">
                  <span className="cssload-heartL"></span>
                  <span className="cssload-heartR"></span>
                  <span className="cssload-square"></span>
                </div>
                <div className="cssload-shadow"></div>
              </div>
              <h3
                style={{ color: "#BE206B", position: "relative", top: "90px" }}
              >
                <strong>Please Wait...Category Data Fetching!</strong>
              </h3>
            </div>
          ) : (
            <>
              {currentCategory.length === 0 ? (
                <div className="card shadow mt-5">
                  <div className="d-flex justify-content-center pb-5">
                    <h2 className="mt-5">
                      No categories found{" "}
                      <i className="fa-regular fa-circle-xmark"></i>
                    </h2>
                  </div>
                </div>
              ) : (
                <div className="container-fluid mt-3">
                  <div className="row">
                    {currentCategory.map((category, index) => (
                      <div key={index} className="col-md-4 mb-4">
                        <div className="card h-100 shadow">
                          <div className="card-body">
                            <div className="text-center">
                              <h5 className="card-title text-uppercase">
                                {category.title}
                              </h5>
                            </div>
                            {category.image && (
                              <div className="text-center">
                                <img
                                  loading="lazy"
                                  src={category.image}
                                  alt={category.title}
                                  className="img-fluid img-thumbnail rounded mb-3"
                                  style={{ maxWidth: "150px" }}
                                />
                              </div>
                            )}
                            <div className="d-flex justify-content-between">
                              <button
                                className="btn btn-sm btn-warning fw-bold text-dark text-uppercase shadow rounded-pill"
                                onClick={() => handleUpdate(category.id)}
                              >
                                Update{" "}
                                <i className="fa-regular fa-pen-to-square mx-1"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-danger fw-bold text-light text-uppercase shadow rounded-pill button"
                                onClick={() => handleDelete(category.id)}
                                disabled={deletingId === category.id}
                              >
                                {deletingId === category.id ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  <>
                                    <span className="button-text">Delete</span>
                                    <i className="fa-solid fa-trash-can mx-1 trash fa-lg"></i>
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Pagination */}
                  <div className="text-center">
                    <nav className="d-flex justify-content-center">
                      <ul className="pagination">
                        {Array.from({
                          length: Math.ceil(
                            categories.length / categoriesPerPage
                          ),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => paginate(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminCategories;
