import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const cache = {};

const getTopUpPackage = async (token) => {
  if (cache.topUpPackages) {
    return cache.topUpPackages;
  }
  try {
    const response = await fetch(
      `${BASE_URL}/seller/transaction/get-top-up-packages`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.detail || "Unknown error";
      alert(`Error : ${errorMessage}`);
      throw new Error(errorMessage);
    }

    const data = await response.json();
    cache.topUpPackages = data;
    return data;
  } catch (error) {
    console.error("Error Fetching the Category: ", error);
  }
};

const getAlltimeSlot = async (token) => {
  if (cache.timeSlots) {
    return cache.timeSlots;
  }
  try {
    const Timeresponse = await fetch(
      `${BASE_URL}/seller/transaction/get-all-time-slots`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!Timeresponse.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await Timeresponse.json();
    cache.timeSlots = data;
    return data;
  } catch (error) {
    console.error("Error Fetching the timeslot: ", error);
  }
};

const getSellerCheckoutResponse = async (adId, packageId, slotId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${BASE_URL}/seller/transaction/seller-checkout-response/${adId}/${packageId}/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      const errorResponse = response.data;
      const errorMessage = errorResponse.detail;
      alert(errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error(error);
  }
};

const promoteSellerPost = async (adId, packageId, slotId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${BASE_URL}/seller/transaction/promote-post/${adId}/${packageId}/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      const msg = response.data.detail;
      console.log(msg);
    }
  } catch (error) {
    if (error.response.status === 500 && error.response) {
      const errorResponse = error.response.data;
      const errorMessage = errorResponse.detail;
      alert(errorMessage);
    }
    console.log(error);
  }
};

const postFreeAd = async (adId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${BASE_URL}/seller/transaction/post-free-ad/${adId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      const msg = response.data.detail;
      console.log(msg);
    }
  } catch (error) {
    if (error.response.status === 500 && error.response) {
      const errorResponse = error.response.data;
      const errorMessage = errorResponse.detail;
      alert(errorMessage);
    }
    console.log(error);
  }
};

export {
  getTopUpPackage,
  getAlltimeSlot,
  getSellerCheckoutResponse,
  promoteSellerPost,
  postFreeAd,
};
