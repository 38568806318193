import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import {
  getDescription,
  updateDescription,
} from "../Services/DescriptionService";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

const AdminUpdateDescription = () => {
  const { id } = useParams();

  const [description, setDescription] = useState({
    headingH1: "",
    headingH2: "",
    description: "",
  });

  const descriptionRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await getDescription(token);
        setDescription(data);
      } catch (error) {
        console.error("Error fetching description:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleBoldClick = () => {
    document.execCommand("bold", false, null);
  };

  const handleInsertLink = () => {
    const url = prompt("Enter the URL:");

    if (url) {
      try {
        const urlObj = new URL(url);
        const hostname = urlObj.hostname;
        document.execCommand(
          "insertHTML",
          false,
          `<a href="${url}" style="color:blue">${hostname}</a>`
        );
      } catch (error) {
        alert("Invalid URL");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDescription((prevDescription) => ({
      ...prevDescription,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const content = descriptionRef.current.innerHTML;

    const dataToSend = {
      headingH1: description.headingH1,
      headingH2: description.headingH2,
      description: content,
    };

    const token = localStorage.getItem("token");

    try {
      await updateDescription(id, dataToSend, token);
      setAlertMessage("Description updated successfully!");
      setAlertType("success");
    } catch (error) {
      console.error("Failed to update description:", error);
      setAlertMessage("Failed to update description. Please try again.");
      setAlertType("danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <main className="container mt-5">
        <Helmet>
          <title>Update Description</title>
          <meta name="description" content="This is the description." />
        </Helmet>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="container">
            <div className="text-center card mb-3 shodow p-2 rounded-pill my-1 border-2">
              <h1>Update Description</h1>
            </div>
            <div className="text-center">
              <button className="btn btn-dark btn-sm rounded-pill mx-2 mb-2 p-2 shadow ">
                <Link
                  className="text-light"
                  style={{ textDecoration: "none" }}
                  to={"/admin/list-Description"}
                >
                  <i className="mx-2 fa-solid fa-chevron-left"></i>Back To Add
                  Description
                </Link>
              </button>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-sm-12">
                <div className="card shadow">
                  <div className="text-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="bold-btn btn btn-md border p-2 mt-3 shadow mx-2"
                        onClick={handleBoldClick}
                      >
                        <div>
                          <strong>Bold</strong>
                        </div>
                      </button>
                      <button
                        className="link-btn btn btn-md border p-2 mt-3 shadow mx-2"
                        onClick={handleInsertLink}
                      >
                        <div>
                          <strong>Add Link</strong>
                        </div>
                      </button>
                    </div>
                  </div>
                  <hr />
                  <form className="p-5" onSubmit={handleSubmit}>
                    {loading && (
                      <div className="alert alert-info" role="alert">
                        Submitting form, please wait...
                      </div>
                    )}
                    {alertMessage && !loading && (
                      <div className={`alert alert-${alertType}`} role="alert">
                        {alertMessage}
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="form-label">
                        <h5>heading H1</h5>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="headingH1"
                        name="headingH1"
                        placeholder="Enter headingH1"
                        onChange={handleChange}
                        value={description.headingH1}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        <h5>heading H2</h5>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="headingH2"
                        name="headingH2"
                        placeholder="Enter headingH2"
                        onChange={handleChange}
                        value={description.headingH2}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        <h4>Description:</h4>
                      </label>
                      <div
                        contentEditable="true"
                        className="form-control"
                        name="description"
                        id="description"
                        ref={descriptionRef}
                      ></div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-dark rounded-pill my-3 shadow"
                        type="submit"
                        disabled={loading}
                      >
                        Update Description{" "}
                        <i className="fa-regular fa-pen-to-square mx-2"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminUpdateDescription;
