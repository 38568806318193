const BASE_URL = process.env.REACT_APP_BASE_URL;


const getDistrictsByState = async (stateName) => {
  try {
    const response = await fetch(`${BASE_URL}/dashboard/state/${stateName}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch districts");
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
    throw error;
  }
};

const getAllCategories = async () => {
  try {
    const response = await fetch(`${BASE_URL}/dashboard/get-all-categories`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch categories");
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

const getPostsByLocationAndCategory = async (requestData) => {
  try {
    const response = await fetch(`${BASE_URL}/dashboard/post-by-location-and-category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch posts");
    }
  } catch (error) {
     
    throw error;
  }
};

const getAllStatesById = async () => {
  try{
    const response = await fetch(`${BASE_URL}/dashboard/get-states`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch states");
    }
  }catch(error){
     
  }
};

export { getDistrictsByState, getAllCategories, getPostsByLocationAndCategory, getAllStatesById };
