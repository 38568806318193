import React, { useEffect, useState } from "react";
import Profile from "../../assets/profile.svg";
import { Link, useNavigate } from "react-router-dom";
import "./../App.css";
import "./../ResponsiveApp.css";

import CryptoJS from "crypto-js";
const SECRET_KEY = "tGh45lP7qR3aS9eC8dN6bM5";

const Sidebar = ({ isOpen, toggle }) => {
  const [email, setEmail] = useState();
  const [verify, setVerify] = useState(true);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const encryptedEmail = localStorage.getItem("email");
    const name = localStorage.getItem("fullname");
    if (encryptedEmail) {
      const bytes = CryptoJS.AES.decrypt(encryptedEmail, SECRET_KEY);
      const decryptedEmail = bytes.toString(CryptoJS.enc.Utf8);

      setVerify(false);
      setEmail(decryptedEmail);
      setUserName(name);
    } else {
      setVerify(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/LogIn");
    window.location.reload();
  };

  const handleDisabledClick = (event) => {
    if (verify) {
      event.preventDefault();
    }
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="w-100 sidebarinsideDiv">
        <button className="close-btn closeBtn" onClick={toggle}>
          &times;
        </button>
        <div className="sidebar-content mt-4 h-100">
          <h5 className="font-start text-secondary">Services for you!</h5>
          <hr />
          <ul
            className="flex flex-col gap-2 sidebar_ul"
            style={{ height: "75vh" }}
          >
            <li>
              <Link
                href="#"
                className="d-flex align-items-center sidebar_hover disabled-link"
                // onClick={toggle}
              >
                <i className="fa-solid fa-location-arrow me-3"></i>
                Locate me
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to="/AddPost"
                className={`d-flex align-items-center sidebar_hover ${
                  verify ? "disabled-link" : ""
                }`}
                onClick={(e) => {
                  toggle();
                  handleDisabledClick(e);
                }}
              >
                <i className="fa-solid fa-camera me-3"></i>
                Post Ad
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to="/"
                className="d-flex align-items-center sidebar_hover"
                onClick={toggle}
              >
                <i
                  className="fa-solid fa-house me-3"
                  style={{ fontSize: "0.8rem" }}
                ></i>
                Home
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to="/Login"
                className={`d-flex align-items-center sidebar_hover ${verify? "" : "disabled-link"} `}
                onClick={toggle}
              >
                <i className="fa-solid fa-id-badge me-3"></i>
                Login
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to="/newUser"
                className="d-flex align-items-center sidebar_hover"
                onClick={toggle}
              >
                <i
                  className="fa-solid fa-user"
                  style={{ fontSize: "0.8rem" }}
                ></i>
                <i
                  className="fa-solid fa-plus me-2"
                  style={{ fontSize: "0.5rem" }}
                ></i>
                Sign up
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to={`/myads`}
                className={`d-flex align-items-center sidebar_hover ${
                  verify ? "disabled-link" : ""
                }`}
                onClick={(e) => {
                  toggle();
                  handleDisabledClick(e);
                }}
              >
                <i className="fa-solid fa-handshake me-2"></i>
                My Ads
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to="/activity"
                className={`d-flex align-items-center sidebar_hover ${
                  verify ? "disabled-link" : ""
                }`}
                onClick={(e) => {
                  toggle();
                  handleDisabledClick(e);
                }}
              >
                <i
                  className="fa-solid fa-chart-line me-3"
                  style={{ fontSize: "0.8rem" }}
                ></i>
                Activity
              </Link>
            </li>
            <hr />
            <li>
              <a
                href="mailto:support@InCallUp.com"
                className="d-flex align-items-center sidebar_hover"
                onClick={toggle}
              >
                <i className="fa-solid fa-comment me-3"></i>
                Support
              </a>
            </li>
            <hr />
            <li>
              <a
                href="tel:+918777074632"
                className="d-flex align-items-center sidebar_hover"
                onClick={toggle}
              >
                <i className="fa-solid fa-comment me-3"></i>
                Contact Us
              </a>
            </li>
            <hr />
            <li>
              <Link
                to={`/privacy&policy`}
                className="d-flex align-items-center sidebar_hover"
                onClick={toggle}
              >
                <i className="fa-solid fa-user-secret me-3"></i>
                Privacy Policy
              </Link>
            </li>
            <hr />
            <li>
              <Link
                to={`/terms&conditions`}
                className="d-flex align-items-center sidebar_hover"
                onClick={toggle}
              >
                <i className="fa-solid fa-handshake me-2"></i>
                Terms & Condition
              </Link>
            </li>
            <hr />
            <li>
              <Link
                href="#"
                className={`d-flex align-items-center sidebar_hover ${
                  verify ? "disabled-link" : ""
                }`}
                onClick={(e) => {
                  toggle();
                  handleDisabledClick(e);
                  handleLogout();
                }}
              >
                <i className="fa-solid fa-right-from-bracket me-3"></i>
                Log out
              </Link>
            </li>
            <li>
              {email && (
                <>
                  {/* Profile section */}
                  <Link
                    to="/profile"
                    style={{ height: "8vh" }}
                    className="border-secondary SidebarUpPorfile border-top py-2 d-flex flex-row gap-2 align-items-center"
                  >
                    <div
                      to="/profile"
                      className="profile rounded-circle bg-secondary 
                  d-flex justify-content-center align-items-center"
                    >
                      <img src={Profile} alt="profile" />
                    </div>
                    <div
                      className="flex flex-column"
                      style={{ flexBasis: "70%" }}
                    >
                      <h5 className="text-dark" style={{ fontSize: "0.6rem" }}>
                        Email Id:{email}
                      </h5>
                    </div>
                  </Link>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
