import React, { useEffect, useState } from "react";
import SideNavigationBar from "./SideNavigationBar";
import {
  deletePopularCityById,
  getAllPopularCity,
} from "../Services/PopularCity";
import "../CSS/ListOfPopularCities.css"; // Ensure the path is correct
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";

const AdminListOfPopularCity = () => {
  const location = useLocation();

  const [popularCities, setPopularCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10); // State for number of items per page
  const [offset, setOffset] = useState(0); // Offset for pagination
  const [filter, setFilter] = useState(""); // State for category filter

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page"), 10);
    if (!isNaN(page)) {
      setCurrentPage(page);
      setOffset(page * perPage);
    }
  }, [location.search]);

  useEffect(() => {
    fetchData();
  }, [currentPage, perPage, filter]); // Fetch data whenever currentPage, perPage, or filter changes

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await getAllPopularCity(token);

      // Combine arrays into a single array
      const popularCitiesArray = Object.values(response).flat();

      setPopularCities(popularCitiesArray);
    } catch (error) {
      console.error("Error fetching popular cities:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await deletePopularCityById(id, token);
      alert("City has been deleted successfully!");
      // Refresh the data
      fetchData();
    } catch (error) {
      console.error("Error deleting popular city:", error);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    setOffset(selected * perPage);
    const params = new URLSearchParams(location.search);
    params.set("page", selected);
    window.history.pushState(
      {},
      "",
      `${location.pathname}? ${params.toString()}`
    );
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page whenever items per page change
  };

  const filteredCities = filter
    ? popularCities.filter((city) => city.categoryName === filter)
    : popularCities;

  const pageCount = Math.ceil(filteredCities.length / perPage);

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="flex-container w-100">
        <div className="main-content " style={{ background: "#333" }}>
          <div className="container">
            <div className=" d-flex mt-5">
              <div className="filter-container float-start ">
                <label htmlFor="categoryFilter" className="text-light">
                  Filter by Category:{" "}
                </label>
                <select
                  id="categoryFilter"
                  value={filter}
                  onChange={handleFilterChange}
                  className="form-select"
                >
                  <option value="">All</option>
                  {/* Add options for each unique category name */}
                  {Array.from(
                    new Set(popularCities.map((city) => city.categoryName))
                  ).map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="per-page-container  mx-5">
                <label htmlFor="perPage" className="text-light">
                  Items per page:{" "}
                </label>
                <select
                  id="perPage"
                  value={perPage}
                  onChange={handlePerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={8}>8</option>
                  <option value={12}>12</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
          <div className="city-list pb-4">
            {filteredCities.length === 0 ? (
              <p>No popular cities available.</p>
            ) : (
              filteredCities
                .slice(offset, offset + perPage)
                .map((city, index) => (
                  <div key={index}>
                    <div className="mycard ">
                      <div className="tools">
                        <div className="circle">
                          <span className="red box"></span>
                        </div>
                        <div className="circle">
                          <span className="yellow box"></span>
                        </div>
                        <div className="circle">
                          <span className="green box"></span>
                        </div>
                      </div>
                      <div className="card__content mx-2">
                        {/* <h5>ID: {city.id}</h5> */}
                        {/* <h5>Location Id: {city.locationId}</h5> */}
                        <h5>
                          <strong>Location Name:</strong> {city.locationName}
                        </h5>
                        {/* <h5>Category Id: {city.categoryId}</h5> */}
                        <h5>
                          <strong>Category Name:</strong> {city.categoryName}
                        </h5>
                      </div>
                      <hr />
                      <div className="text-center">
                        <button
                          className="btn btn-sm button text-light"
                          onClick={() => handleDelete(city.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminListOfPopularCity;
