import { HelmetProvider } from "react-helmet-async";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Test from "./Components/Test";
import AdListing from "./Components/AdListing";
import EditPage from "./Components/EditPage";
import AdminDashboard from "./Components/AdminDashboard";

import AdminRegister from "./Components/AdminRegister";

// Chandan's Components
import { Suspense, lazy } from "react";
import ScrollToTop from "./Components/ScrollToTop.jsx";
import SpaForm from "./Components/SpaForm.jsx";
import CheckOut from "./Components/UserPayment/CheckOut.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.jsx";
import LayoutForOthers from "./Components/LayoutForOthers.jsx";
import Layout from "./Components/Layout/Layout";
import AdminCategories from "./Components/AdminCategories.jsx";
import AdminCreateCategory from "./Components/AdminCreateCategory.jsx";
import AdminDescription from "./Components/AdminDescription.jsx";
import AdminListDescription from "./Components/AdminListDescription.jsx";
import AdminPopularCity from "./Components/AdminPopularCity.jsx";
import AdminListOfPopularCity from "./Components/AdminListOfPopularCity.jsx";
import AdminLogin from "./Components/AdminLogin.jsx";
import AdminUpdateCategory from "./Components/AdminUpdateCategory.jsx";
import AdminUpdateLocation from "./Components/AdminUpdateLocation.jsx";
import AdminUpdateDescription from "./Components/AdminUpdateDescription.jsx";
import AdminBlogs from "./Components/AdminBlogs.jsx";
import AdminLocation from "./Components/AdminLocation.jsx";
import AdminPaymentDetails from "./Components/AdminPaymentDetails.jsx";
import AdminBankingActivitiesComponent from "./Components/AdminBankingActivitiesComponent.jsx";

const Home = lazy(() => import("./Components/Home/Home"));
const LogIn = lazy(() => import("./Components/LogIn && SignIN/LogIn"));
const Register = lazy(() => import("./Components/LogIn && SignIN/Register"));
const Posts = lazy(() => import("./Components/Posts/Posts.jsx"));
const Posts2 = lazy(() => import("./Components/Posts/Posts2.jsx"));

const SeparateItems = lazy(() =>
  import("./Components/SeparateItems/SeparateItems.jsx")
);
const AddPost = lazy(() => import("./Components/AddPost/AddPost.jsx"));
const AdminListLocation = lazy(() => import("./Components/AdminListLocation"));

const Activity = lazy(() => import("./Components/Activity/Activity"));
const Pacakages = lazy(() => import("./Components/Pacakages/Pacakages.jsx"));
const MyAds = lazy(() => import("./Components/myAds/MyAds.jsx"));
// const PopularCity = lazy(() => import("./Components/PopularCity.jsx"));

const TermsCondition = lazy(() =>
  import("./Components/Terms&Condition/TermsCondition.jsx")
);
const QRPage = lazy(() => import("./Components/UserPayment/QRPage.jsx"));
const SelectAmount = lazy(() =>
  import("./Components/UserPayment/SelectAmount.jsx")
);
const PaymentConfirmation = lazy(() =>
  import("./Components/UserPayment/PaymentConfirmation.jsx")
);

const ErrorPage = lazy(() => import("./Components/ErrorPage.jsx"));
const TopupPage = lazy(() => import("./Components/AddPost/TopupPage.jsx"));
// import { AuthProvider } from "./Components/AuthProvider.jsx";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <Suspense>
          <Routes>
            <Route exact path="/" element={<Layout />}>
              <Route exact index element={<Home />} />
              <Route path="*" element={<ErrorPage />} />
              <Route path="activity" element={<Activity />} />
              {/* <Route path="profile" element={<ProfilePage />} /> */}
              <Route path=":title" element={<Posts />} />
              <Route path=":title/:postLocation" element={<Posts2 />} />
              <Route path="ad/:cardName/:adId" element={<SeparateItems />} />
              <Route path="spa" element={<SpaForm />} />
            </Route>

            <Route path="/" element={<LayoutForOthers />}>
              <Route path="/register" element={<Register />} />
              <Route path="terms&conditions" element={<TermsCondition />} />
              <Route path="select-amount" element={<SelectAmount />} />
              <Route path="/LogIn" element={<LogIn />} />
              <Route
                path="checkout/:adId/:packageId/:slotId"
                element={<CheckOut />}
              />
              <Route path="myads" element={<MyAds />} />
              <Route path="payment-confirm" element={<PaymentConfirmation />} />
              <Route path="topup/:adId" element={<TopupPage />} />
              <Route path="privacy&policy" element={<PrivacyPolicy />} />
              <Route path="qrpage" element={<QRPage />} />
              <Route path="newUser" element={<Register />} />
              <Route path="AddPost" element={<AddPost />} />
              <Route path="pacakages" element={<Pacakages />} />
            </Route>

            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/register" element={<AdminRegister />}></Route>
            <Route path="/admin/" element={<AdminLogin />} />
            <Route path="/admin/blogs" element={<AdminBlogs />}></Route>
            <Route path="/admin/adlisting" element={<AdListing />}></Route>
            <Route path="/admin/edit/:index" element={<EditPage />}></Route>
            <Route path="/admin/dashboard" element={<AdminDashboard />}></Route>
            <Route
              path="/admin/description"
              element={<AdminDescription />}
            ></Route>
            <Route
              path="/admin/categories"
              element={<AdminCategories />}
            ></Route>
            <Route
              path="/admin/create-category"
              element={<AdminCreateCategory />}
            ></Route>
            <Route
              path="/admin/updateCategory/:id"
              element={<AdminUpdateCategory />}
            ></Route>
            <Route
              path="/admin/list-Description"
              element={<AdminListDescription />}
            ></Route>
            <Route
              path="/admin/update-Description/:id"
              element={<AdminUpdateDescription />}
            ></Route>
            <Route path="/admin/location" element={<AdminLocation />}></Route>
            <Route
              path="/admin/list-location"
              element={<AdminListLocation />}
            ></Route>
            <Route
              path="/admin/update-location/:id"
              element={<AdminUpdateLocation />}
            ></Route>
            <Route
              path="/admin/pouplar-cities"
              element={<AdminPopularCity />}
            ></Route>
            <Route
              path="/admin/list-pouplar-cities"
              element={<AdminListOfPopularCity />}
            ></Route>
            <Route
              path="/admin/admin-payment-details"
              element={<AdminPaymentDetails />}
            ></Route>
            <Route
              path="/admin/admin-banking-details"
              element={<AdminBankingActivitiesComponent />}
            ></Route>

            <Route path="/test" element={<Test />}></Route>
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
