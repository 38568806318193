import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SideNavigationBar from "./SideNavigationBar";
import { getLocationById, updateLocation } from "../Services/Location";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import Swal from "sweetalert2";
const AdminUpdateLocation = () => {
  const { id } = useParams();

  const [location, setLocation] = useState(null);
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    metaTitle: "",
    metaDescription: "",
    locationDescription: "",
    canonicalLink: "",
    h1Tag: "",
    h2Tag: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const token = localStorage.getItem("token");
        const locationData = await getLocationById(id, token);
        setLocation(locationData);
        setFormData({
          state: locationData.state,
          district: locationData.district,
          metaTitle: locationData.metaTitle,
          metaDescription: locationData.metaDescription,
          locationDescription: locationData.locationDescription,
          canonicalLink: locationData.canonicalLink,
          h1Tag: locationData.h1Tag,
          h2Tag: locationData.h2Tag,
        });
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };
    fetchLocation();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevFormData) => {
      if (name === "stateImage" && files.length > 0) {
        return {
          ...prevFormData,
          stateImage: files[0],
        };
      } else {
        return {
          ...prevFormData,
          [name]: value,
        };
      }
    });
  };

  const handleDescriptionChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      locationDescription: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      // Simulate a delay of 1 second (1000 ms)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await updateLocation(id, formData, token);

      // alert("Data Updated Successfully!");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Data Updated Successfully!",
        showConfirmButton: false,
        timer: 1000,
      });

      setShowAlert(true);

      setTimeout(() => {
        window.location.href = `/admin/update-location/${id}`;
      }, 1000);
    } catch (error) {
      console.error("Error updating location:", error);
      // alert("Error updating location.");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error updating location",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="w-100" style={{ background: "#333" }}>
        <div className="container mt-2    ">
          {/* <div className="text-center card mb-3 shadow p-2 rounded-pill my-1 border-2">
            <h1>
              <i className="fa-solid fa-wrench"></i> Update Location
            </h1>
            <div className="container">
              <button className="btn btn-dark rounded-pill mx-5 mb-1">
                <Link
                  to={"/admin/list-location"}
                  className="text-light "
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa-solid fa-chevron-left"></i> Back To List
                </Link>
              </button>
            </div>
          </div> */}
          <div className="mb-2 mt-5">
            <Link
              to={"/admin/list-location"}
              className="text-light "
              style={{ textDecoration: "none" }}
            >
              <i className="fa-solid fa-chevron-left"></i> Back To List
            </Link>
          </div>
          <div>
            <form
              className="card px-5 shadow rounded-4 border-2"
              onSubmit={handleSubmit}
            >
              <div className="mb-2 text-center">
                <h5 className="mt-2">
                  <strong>Update Location </strong>
                </h5>
              </div>
              <div>
                <label htmlFor="state" className="form-label">
                  <strong>State</strong>
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  className="form-control"
                  placeholder="Enter state"
                  value={formData.state}
                  onChange={handleInputChange}
                  disabled
                />
              </div>

              <div>
                <label htmlFor="district" className="form-label">
                  <strong>District</strong>
                </label>
                <input
                  type="text"
                  name="district"
                  id="district"
                  className="form-control"
                  placeholder="Enter district"
                  value={formData.district}
                  onChange={handleInputChange}
                  disabled
                />
              </div>

              {/* //! Meta Title  */}
              <div className="mb-3">
                <label htmlFor="metaTitle" className="form-label">
                  <strong>Meta Title:</strong>
                </label>
                <input
                  type="text"
                  name="metaTitle"
                  id="metaTitle"
                  className="form-control"
                  placeholder="Enter Meta Title"
                  value={formData.metaTitle}
                  onChange={handleInputChange}
                />
              </div>

              {/* //! META DESCRIPTION */}
              <div className="mb-3">
                <label htmlFor="locationDescription" className="form-label">
                  <strong>Meta Description:</strong>
                </label>
                <textarea
                  type="text"
                  name="metaDescription"
                  id="metaDescription"
                  className="form-control"
                  placeholder="Enter Meta Description"
                  value={formData.metaDescription}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label htmlFor="canonicalLink" className="form-label">
                  <strong>Canonical Link</strong>
                </label>
                <input
                  type="text"
                  name="canonicalLink"
                  id="canonicalLink"
                  className="form-control"
                  placeholder="Enter Canonical Link"
                  value={formData.canonicalLink}
                  onChange={handleInputChange}
                />
              </div>

              {/* //! LOCATION DESCRIPTION */}
              <div className="mb-3">
                <label htmlFor="locationDescription" className="form-label">
                  <strong>Location Description:</strong>
                </label>
                <ReactQuill
                  theme="snow"
                  value={formData.locationDescription}
                  onChange={handleDescriptionChange}
                  className=" "
                  placeholder="Enter Location Description"
                />
              </div>

              <div>
                <label htmlFor="h1Tag" className="form-label">
                  <strong>h1Tag Tag</strong>
                </label>
                <input
                  type="text"
                  name="h1Tag"
                  id="h1Tag"
                  className="form-control"
                  placeholder="Enter h1Tag tag"
                  value={formData.h1Tag}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label htmlFor="h2Tag" className="form-label">
                  <strong>h2Tag </strong>
                </label>
                <input
                  type="text"
                  name="h2Tag"
                  id="h2Tag"
                  className="form-control"
                  placeholder="Enter h2Tag "
                  value={formData.h2Tag}
                  onChange={handleInputChange}
                />
              </div>

              <div className="text-center">
                <button
                  className="btn btn-dark rounded-pill my-3 "
                  type="submit"
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Alert Modal */}
      <div
        className={`modal ${showAlert ? "show d-block" : ""}`}
        tabIndex="-1"
        style={{ display: showAlert ? "block" : "none" }}
        aria-labelledby="alertModalLabel"
        aria-hidden={!showAlert}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="alertModalLabel">
                Success
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
            <div className="modal-body">Location updated successfully!</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setShowAlert(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {showAlert && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default AdminUpdateLocation;
