import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideNavigationBar from "./SideNavigationBar.jsx";
import { deletePost, getAllPost } from "../Services/AdListing.js";
import "../CSS/AdListing.css";
import Swal from "sweetalert2";

const AdListing = () => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [showFullTitle, setShowFullTitle] = useState({});
  const [showFullDescription, setShowFullDescription] = useState({});
  const itemsPerPage = 8;

  const location = useLocation();

  // const searchParams = new URLSearchParams(location.search);
  // const parent = searchParams.get("parent") || "Undefined";
  const [showImagesIndex, setShowImagesIndex] = useState(null);

  const handleShowFullTitle = (index) => {
    setShowFullTitle((prev) => ({ ...prev, [index]: true }));
  };

  const handleShowFullDescription = (index) => {
    setShowFullDescription((prevShowFullDescription) => ({
      ...prevShowFullDescription,
      [index]: !prevShowFullDescription[index],
    }));
  };

  const handleEdit = (index, selectedItem) => {
    const queryString = Object.keys(selectedItem)
      .map((key) => key + "=" + encodeURIComponent(selectedItem[key]))
      .join("&");
    window.location.href = `/admin/edit/${index}?${queryString}`;
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await deletePost(id, token);
      const newData = data.filter((category) => category.id !== id);
      setData(newData);
      Swal.fire({
        icon: "error",
        text: "Category Deleted !!.",
      });
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowImages = (index) => {
    setShowImagesIndex(showImagesIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.classList.contains("expandable-title") &&
      !event.target.classList.contains("expandable-description")
    ) {
      setShowFullTitle({});
      setShowFullDescription({});
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const categoryData = await getAllPost(token);
        console.table("Category Data: ", categoryData);

        setCategories(categoryData);
        setData(categoryData);
        setLoading(false); // Set loading state to false when data is fetched
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false); // Set loading state to false even if there's an error
      }
    };
    fetchData();

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handlePopstate = (event) => {
      if (event.state && event.state.page) {
        setCurrentPage(event.state.page);
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="d-flex" style={{ background: "#333" }}>
      <SideNavigationBar />
      <div className="my-container">
        {/* <div className="text-center mt-2">
          <h1 className="text-uppercase">
            <i className="fa-solid fa-list"></i> AD Listing 
          </h1>
        </div> */}

        {loading ? (
          <div className="loader-container">
            <div className="lava-lamp">
              <div className="bubble"></div>
              <div className="bubble1"></div>
              <div className="bubble2"></div>
              <div className="bubble3"></div>
            </div>
          </div>
        ) : (
          <div className="container card mt-5 border border-1 p-3 rounded rounded-4 shadow my-container">
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-regular fa-calendar-days"></i> Category
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    <i className="fa-regular fa-bookmark"></i> Title
                  </th>
                  <th
                    scope="col"
                    style={{ width: "25%", wordWrap: "break-word" }}
                  >
                    <i className="fa-solid fa-bars"></i> Description
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-solid fa-phone"></i> Contact
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-solid fa-image"></i> Photos
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-regular fa-user"></i> User Name
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-solid fa-city"></i> City
                  </th>
                  <th scope="col" style={{ width: "5%" }}>
                    <i className="fa-solid fa-cake-candles"></i> Age
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-solid fa-envelope"></i> Verified Email
                  </th>
                  <th scope="col" style={{ width: "5%" }}>
                    <i className="fa-solid fa-calculator"></i> ADs
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    <i className="fa-solid fa-exclamation"></i> Action
                  </th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {currentData.map((category, index) => (
                  <tr key={index}>
                    <td className="border" scope="row">
                      {category.catagory}
                    </td>
                    <td className="border">
                      {category.title.length > 20 ? (
                        showFullTitle[index] ? (
                          category.title
                        ) : (
                          <span>
                            {category.title.substring(0, 20)}{" "}
                            <span
                              className="text-primary expandable-title"
                              onClick={() => handleShowFullTitle(index)}
                            >
                              ...
                            </span>
                          </span>
                        )
                      ) : (
                        category.title
                      )}
                    </td>
                    <td className="border">
                      {showFullDescription[index] ? (
                        category.description
                      ) : (
                        <span>
                          {category.description.substring(0, 10)}{" "}
                          <span
                            className="text-primary expandable-description"
                            onClick={() => handleShowFullDescription(index)}
                          >
                            ...
                          </span>
                        </span>
                      )}
                    </td>
                    <td className="border">{category.phone}</td>

                    <td className="border">
                      <button
                        className="rounded-pill show-btn shadow"
                        onClick={() => handleShowImages(index)}
                      >
                        {showImagesIndex === index ? "Hide" : " Images"}{" "}
                        <i className="fa-solid fa-angle-right"></i>
                      </button>
                      {showImagesIndex === index && (
                        <div className="image-grid">
                          {category.images &&
                            category.images.map((image, idx) => (
                              <img
                                loading="lazy"
                                key={idx}
                                src={image}
                                alt="category"
                                className="img-thumbnail"
                              />
                            ))}
                        </div>
                      )}
                    </td>
                    <td className="border">{category.userName}</td>
                    <td className="border">
                      <strong className="text-primary">
                        {category.location}
                      </strong>
                    </td>
                    <td className="border">{category.age}</td>
                    <td className="border">
                      <div className="form-check form-switch d-flex justify-content-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                        />
                      </div>
                    </td>
                    <td className="border"> </td>
                    <td className="text-center border">
                      <div className="justify-content-center mt-1">
                        <button
                          className="edit-btn btn-sm shadow"
                          onClick={() => handleEdit(index, category)}
                        >
                          <i className="fa-solid fa-pen-fancy"></i>
                        </button>
                        <button
                          className="delete-btn btn-sm shadow"
                          onClick={() => handleDelete(category.id)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {totalPages > 1 && (
          <nav>
            <ul className="pagination justify-content-center mt-5">
              {[...Array(totalPages)].map((_, pageIndex) => (
                <li
                  key={pageIndex}
                  className={`page-item ${
                    currentPage === pageIndex + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageIndex + 1)}
                  >
                    {pageIndex + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default AdListing;
