import React from 'react'
import { Outlet } from 'react-router-dom';
import Navbar2 from './Navbar/Navbar2.jsx';
import Footer from "./Footer/Footer"

const LayoutForOthers = () => {
  return (
    <div style={{minHeight:"100vh", background:"white"}}>
      <Navbar2 />
      <Outlet style={{minHeight:"100vh"}}/>
      <Footer />
    </div>
  )
}

export default LayoutForOthers