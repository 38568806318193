import React, { useState } from "react";
import "../CSS/Description.css";
import { Helmet } from "react-helmet-async";
import { sendDescription } from "../Services/DescriptionService";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AdminDescription = () => {
  const [description, setDescription] = useState({
    headingH1: "",
    headingH2: "",
    description: "",
  });

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDescription((prevDescription) => ({
      ...prevDescription,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSend = {
      headingH1: description.headingH1,
      headingH2: description.headingH2,
      description: description.description,
    };

    const token = localStorage.getItem("token");

    try {
      await sendDescription(dataToSend, token);

      setAlertMessage("Description sent successfully!");
      setAlertType("success");
      setLoading(false);
    } catch (error) {
      console.error("Failed to send description:", error);
      setAlertMessage("Failed to send description. Please try again.");
      setAlertType("danger");
      setLoading(false);
    }
  };

  const handleShowDescription = () => {
    try {
      window.location.href = "/admin/list-Description";
    } catch (error) {}
  };

  const handleDescriptionChange = (value) => {
    setDescription((prevDescription) => ({
      ...prevDescription,
      description: value,
    }));
  };

  return (
    <div className="d-flex" style={{ background: "#333" }}>
      <SideNavigationBar />
      <main
        className="container d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Helmet>
          <title>Description </title>
          <meta name="description" content="This is the description." />
        </Helmet>
        <div className="col-lg-12 col-md-12 col-sm-12  ">
          <div className="text-center card mb-3 shodow p-2 rounded-pill my-1 border-2">
            <h1>
              {" "}
              <i className="fa-solid fa-rectangle-list mx-2"></i>Description{" "}
            </h1>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card col-lg-12 col-md-12 col-sm-12 shadow">
                <div className="text-center">
                  <div className="col-lg-6 col-md-6 col-sm-6"></div>
                  <div className="col-lg-6 col-md-6 col-sm-6"></div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <button
                      className="link-btn btn btn-md float-start mx-2 border p-2 mt-3 shadow"
                      onClick={handleShowDescription}
                    >
                      <div>
                        <i className="fa-solid fa-link link-icon"> </i>{" "}
                        <strong>Show Descriptions</strong>
                      </div>
                    </button>
                  </div>
                </div>
                <hr />
                <form className="p-5 mx-3" onSubmit={handleSubmit}>
                  {loading && (
                    <div className="alert alert-info" role="alert">
                      Submitting form, please wait...
                    </div>
                  )}
                  {alertMessage && !loading && (
                    <div className={`alert alert-${alertType}`} role="alert">
                      {alertMessage}
                    </div>
                  )}
                  <div className="mb-3">
                    <label className="form-label">
                      <h5>heading H1</h5>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="headingH1"
                      name="headingH1"
                      placeholder="Enter headingH1"
                      onChange={handleChange}
                      value={description.headingH1}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <h5>heading H2</h5>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="headingH2"
                      name="headingH2"
                      placeholder="Enter headingH2"
                      onChange={handleChange}
                      value={description.headingH2}
                    />
                  </div>
                  {/*//! Description */}

                  <ReactQuill
                    theme="snow"
                    value={description.description}
                    onChange={handleDescriptionChange}
                    placeholder="Enter Description"
                    id="description"
                    name="description"
                  />

                  <div className="text-center">
                    <button
                      className="btn btn-dark rounded-pill my-3 shadow "
                      type="submit"
                      disabled={loading}
                    >
                      Submit Description <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDescription;
