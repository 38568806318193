import axios from "axios";
import CryptoJS from "crypto-js";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const SECRET_KEY = "tGh45lP7qR3aS9eC8dN6bM5";

const register = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/admins`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error to Registeration !!!!", error);
    throw error;
  }
};

const login = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const encryptedEmail = CryptoJS.AES.encrypt(
      response.data.email,
      SECRET_KEY
    ).toString();
    localStorage.setItem("email", encryptedEmail);

    return response.data;
  } catch (error) {
    console.error("Error to Registeration !!!!", error);
    throw error;
  }
};

export { register, login };
