import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      className="container"
      style={{ paddingTop: "80px", minHeight: "80vh" }}
    >
      <h1 className="fs-2 fw-bold text-center text-uppercase">
        <u>Privacy Statement</u>
      </h1>
      <br />
      <p className="fw-bolder text-secondary">
        Greetings from the InCallUp Dating classified (the "Website"). We
        provide information below on how we handle users' personal data when
        they browse our website, in compliance with Article 13 of EU Regulation
        2016/679. We suggest reading the terms and conditions on our website if
        you're interested in knowing the guidelines we adhere to when
        maintaining it and safeguarding your personal data. If you are a user
        who has registered for our web services or a general visitor, please
        select the relevant section to learn more about the privacy and
        processing of your personal data. This statement's sections on the
        concerned party's rights, data controllers and processors, and the
        transfer of users' personal data outside of the EU
      </p>
      <br />
      <p>
        <span>2.</span>
        <span className="fw-bold text-secondary">
          {" "}
          WHO IS THE DATA CONTROLLER OF USERS’ PERSONAL DATA
        </span>
        <br />
        <p>
          Recubans LDA (hereinafter referred to as{" "}
          <span className="fw-bolder">“Recubans”</span> or the{" "}
          <span className="fw-bolder">“Company”</span>), with administrative
          headquarters in Portugal is the data controller of users’ personal
          data who consult and use the InCallUp e-shop. You can contact the
          Company by sending an email to the following address:
          <a href="mailto:support@InCallUp.com" className="fw-bolder">
            support@InCallUp.com
          </a>
        </p>
      </p>
      <br />
      <p>
        <span>3.</span>
        <span className="fw-bold text-secondary"> INFORMATION AGRAVADE</span>
        <p className="">
          Even if users visit the company's website without registering or
          submitting any information via online forms, the company may still
          collect and use their personal information. In some sections of the
          website, the Company also employs cookies to track user activity and
          identify pages that are frequently visited. To fully understand how
          their personal data is processed in this regard, users are encouraged
          to review the Cookie Policy in conjunction with the information
          provided in this statement. Users are considered to have accepted this
          Privacy Policy's terms and conditions as well as the website's terms
          and conditions by using this website. It is advised that users who
          disagree with these terms and conditions.
        </p>
      </p>
      <br />
      <p>
        <span>4.</span>
        <span className="fw-bold text-secondary text-uppercase">
          {" "}
          Types of Personal Information
        </span>
        <br />
        <p>
          Please note that in order to use the services and content on our
          website, you might need to submit personal information about yourself,
          like your name, surname, residential address, email address, etc. We
          might use your name and email address, among other contact
          information, if you have received a gift card, to make sure it gets
          delivered and is used. Note that this information is supplied to the
          website at the time of purchase by the person who purchased the gift
          card. You might also be prompted for more information when making a
          purchase on our website, like your billing, shipping, and payment
          details.
        </p>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
