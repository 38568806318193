import React, { useState } from "react";
import "../CSS/SideNavigationbar.css";
import "../JS/SideNavigationBar.js";
import "./MyApp.css";
import { Link, useNavigate } from "react-router-dom";

/* 
    Author: Nihal Sherkar
    Date: 28-05-2024 
    Description: This is sidenavbar.
*/
/* 
    Author: Nihal Sherkar
    Date: 06-06-2024 
    Description: This is Login and category page.
*/
const SideNavigationBar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [showLogoutAlert, setShowLogoutAlert] = useState(false); // State to manage logout alert visibility
  const navigate = useNavigate();

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/admin");
    setShowLogoutAlert(true); // Show the logout alert
    // Optional: You may want to clear the alert after a certain duration
    setTimeout(() => {
      setShowLogoutAlert(false);
    }, 1000);
  };

  const localEmail = localStorage.getItem("email");

  return (
    <div className="sidenavigationBar">
      {/* Bootstrap Alert for Logout Success */}
      {showLogoutAlert && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Logout successful!
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      {/* USER AVATAR, NAME AND LINK FOR ACCOUNTS */}
      <div className="user-info d-flex align-items-center">
        <div className="user-avatar me-2 d-flex align-items-center justify-content-center   ">
          <i className="fa-regular fa-user "></i>
        </div>
        <div
          className="user-name d-flex align-items-center"
          onClick={toggleUserDropdown}
        >
          {/* {localStorage.getItem("email")} */}
          {localEmail ? "Admin" : ""}
          <i className="fa-solid fa-caret-down downArrow ms-2"></i>
        </div>
        {userDropdownOpen && (
          <div className="user-account-dropdown dropdown-menu show">
            <a className="dropdown-item" href="!">
              <i className="fa-regular fa-user"></i> My Account
            </a>
            {/* <a className="dropdown-item" href="/admin/register">
              <i class="fa-solid fa-align-justify"></i> Register
            </a> */}
            <a className="dropdown-item" href="/admin">
              <i className="fa-solid fa-right-from-bracket"></i> Login
            </a>
            <Link className="dropdown-item" href="!" onClick={handleSignOut}>
              <i class="fa-solid fa-delete-left"></i> Sign Out
            </Link>
          </div>
        )}
      </div>

      {/* Dashboard */}
      <div className="menu-item">
        <a href="/admin/dashboard?parent=Dashboard">
          <i
            className="fa-solid fa-house mx-1"
            style={{ textDecoration: "none" }}
          ></i>
          Dashboard
        </a>
      </div>

      {/* Listing */}
      <div
        className="menu-item dropdown"
        onClick={() => toggleDropdown("listing")}
      >
        <div className="menu-item-content d-flex justify-content-between">
          <i className="fa-solid fa-bars-staggered mx-1"></i> Listing
          <i className="fa-solid fa-caret-down downArrow"></i>
        </div>
        {openDropdown === "listing" && (
          <div className="dropdown-content">
            <a href="!">List</a>
            <a href="!">Categories</a>
            <a href="!">Pictures</a>
            <a href="!">Listing Types</a>
            <a href="!">Custom Fields</a>
          </div>
        )}
      </div>

      {/* Users */}
      <div
        className="menu-item dropdown"
        onClick={() => toggleDropdown("users")}
      >
        <div className="menu-item-content d-flex justify-content-between">
          <i className="fa-solid fa-user-group mx-1"></i>Users
          <i className="fa-solid fa-caret-down downArrow"></i>
        </div>
        {openDropdown === "users" && (
          <div className="dropdown-content ">
            <a href="!">List</a>
            <a href="!">Roles</a>
            <a href="!">Permissions</a>
            <a href="!">Titles</a>
          </div>
        )}
      </div>

      {/* Payments */}
      <div
        className="menu-item dropdown"
        onClick={() => toggleDropdown("payments")}
      >
        <div className="menu-item-content d-flex justify-content-between">
          <i className="fa-solid fa-bars-staggered mx-1"></i> Payments
          <i className="fa-solid fa-caret-down downArrow"></i>
        </div>
        {openDropdown === "payments" && (
          <div className="dropdown-content">
            <a href="/admin/admin-payment-details">Transactions</a>
            <a href="/admin/admin-banking-details">Banking</a>
          </div>
        )}
      </div>

      {/* Pages */}
      <div className="menu-item">
        <i className="fa-solid fa-book-open mx-1"></i>Pages
      </div>

      {/* Settings */}
      <div
        className="menu-item dropdown "
        onClick={() => toggleDropdown("settings")}
      >
        <div className="menu-item-content d-flex justify-content-between">
          <i className="fa-solid fa-gear mx-1"></i>Settings
          <i className="fa-solid fa-caret-down downArrow"></i>
        </div>
        {openDropdown === "settings" && (
          <div className="dropdown-content">
            {/* <a href="!">General</a> */}
            <a href="!">Application</a>
            <a href="!">Style</a>
            <a href="!">List & Search</a>
            <a href="!">Single (Page & Form)</a>
            <a href="!">Mail</a>
            <a href="!">SMS</a>
            <a href="!">Upload</a>
            <a href="!">GEO Location</a>
            <a href="!">Security</a>
            <a href="!">Social Login</a>
            <a href="!">Social NEtwork</a>
            <a href="!">Optimization</a>
            {/* <a href="!">SEO</a> */}
            <a href="!">Others</a>
            <a href="!">Cron</a>
            <a href="!">Footer</a>
            <a href="!">Backup</a>
            <a href="!">Watermark</a>

            <a href="!" className="mt-3">
              Language
            </a>
            <a href="!">Homepage</a>
            <a href="!">Meta Tags</a>
            <a href="!" className="  ">
              Packages
            </a>
            <a href="!" className="  ">
              Payment Methods
            </a>
            <a href="!" className="  ">
              Advertising
            </a>
            <a href="!" className="  ">
              International
            </a>
            <a href="!" className="  ">
              Countries
            </a>
            <a href="!" className="  ">
              Currencies
            </a>

            <a href="!" className="mt-3">
              Black List
            </a>
            <a href="!" className="  ">
              Report Types
            </a>
          </div>
        )}
      </div>
      {/* SEO */}
      <div className="menu-item dropdown" onClick={() => toggleDropdown("SEO")}>
        <div className="menu-item-content d-flex justify-content-between">
          <i className="fa-solid fa-user-group mx-1"></i>SEO
          <i className="fa-solid fa-caret-down downArrow"></i>
        </div>
        {openDropdown === "SEO" && (
          <div className="dropdown-content ">
            <a href="/admin/blogs?parent=SEO" className="mt-2">
              Blog Post
            </a>
            <a href="/admin/adlisting?parent=SEO">AD Listing</a>
            <a href="/admin/description?parent=SEO">Description</a>
            <a href="/admin/categories?parent=SEO">Categories</a>
            <a href="/admin/location?parent=SEO">Location</a>
          </div>
        )}
      </div>
      {/* Popular City */}
      <div
        className="menu-item dropdown"
        onClick={() => toggleDropdown("pouplarCities")}
      >
        <div className="menu-item-content d-flex justify-content-between">
          <i className="fa-solid fa-bars-staggered mx-1"></i> Popular City
          <i className="fa-solid fa-caret-down downArrow"></i>
        </div>
        {openDropdown === "pouplarCities" && (
          <div className="dropdown-content">
            <a href="/admin/pouplar-cities">Add City</a>
          </div>
        )}
      </div>

      {/* Plugins */}
      <div className="menu-item">
        <i className="fa-solid fa-arrow-up-right-from-square mx-1"></i>Plugins
      </div>

      {/* Clear Cache */}
      <div className="menu-item">
        <i className="fa-solid fa-recycle mx-1"></i>Clear Cache
      </div>

      {/* backups */}
      <div className="menu-item">
        <i className="fa-solid fa-hard-drive mx-1"></i>backups
      </div>

      {/* System */}
      <div className="menu-item">
        <i className="fa-solid fa-circle-exclamation mx-1"></i>System
      </div>
    </div>
  );
};

export default SideNavigationBar;
