import React, { useMemo, useState, useEffect } from "react";
import SideNavigationBar from "./SideNavigationBar";
import ReactPaginate from "react-paginate";
import "../CSS/AdminPaymentDetails.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getAllPayments, getAllPaymentStatuses, updatePaymentStatus } from "../Services/AdminPyamentsService";

const AdminPaymentDetails = () => {
  const [data, setData] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 8; // Number of items per page

  const fetchData = async () => {
    try {
      const paymentsData = await getAllPayments(localStorage.getItem("token"));
      setData(paymentsData);
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const status = await getAllPaymentStatuses(localStorage.getItem("token"));
        setPaymentStatus(status);
      } catch (error) {
        console.error("Error fetching status details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStatuses();
  }, []);

  // Filter data based on the search query
  const filteredData = useMemo(() => {
    return data.filter((item) =>
      item.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, data]);

  // Calculate the offset and current items based on the filtered data
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);

  // Handle status change
  const handleStatusChange = async (id, newStatus) => {
    try {
      const token = localStorage.getItem("token");
      await updatePaymentStatus(token, id, newStatus);
      toast.success(`Status updated to ${newStatus}`);
      // Re-fetch data to refresh the page
      fetchData();
    } catch (error) {
      toast.error(`Failed to update status: ${error}`);
    }
  };

  // Handle page change
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0); // Reset to the first page whenever the search query changes
  };

  // Function to get color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "APPROVED":
        return "green";
      case "REJECTED":
        return "red";
      default:
        return "black";
    }
  };

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="all_data">
        <div className="table">
          <section className="table_header">
            <h1>Admin Payment</h1>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search by email..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control search_input"
              />
            </div>
          </section>
          <section className="table_body">
            {loading ? (
              <p>Loading...</p>
            ) : filteredData.length === 0 ? (
              <p className="no_data">Loading...</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Username</th>
                      <th>Amount</th>
                      <th>UPI ID</th>
                      <th>Transaction ID</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.email}</td>
                        <td>{item.username}</td>
                        <td>{item.amount}</td>
                        <td>{item.upiId}</td>
                        <td>{item.transactionId}</td>
                        <td>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={item.paymentStatus}
                            onChange={(e) => handleStatusChange(item.id, e.target.value)}
                            style={{
                              fontWeight: "bold",
                              color: "white",
                              backgroundColor: getStatusColor(item.paymentStatus),
                            }}
                          >
                            <option value={item.paymentStatus}>
                              {item.paymentStatus}
                            </option>
                            {paymentStatus
                              .filter((status) => status !== item.paymentStatus)
                              .map((status, idx) => (
                                <option key={idx} value={status}>
                                  {status}
                                </option>
                              ))}
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {filteredData.length > itemsPerPage && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                )}
              </>
            )}
          </section>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminPaymentDetails;
