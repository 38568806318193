import React, { useState } from "react";
import { createCategory } from "../Services/CategoriesServices";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

const AdminCreateCategory = () => {
  const [formData, setFormData] = useState({
    title: "",
    metaDescription: "",
    metaTitle: "",
    image: null,
  });

  const [alertMessage, setAlertMessage] = useState(null); // State for alert message
  const [alertType, setAlertType] = useState(null); // State for alert type
  const [loading, setLoading] = useState(false); // State for loading

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      if (name === "image" && files.length > 0) {
        return {
          ...prevFormData,
          image: files[0],
        };
      } else {
        return {
          ...prevFormData,
          [name]: value,
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("metaDescription", formData.metaDescription);
      formDataToSend.append("metaTitle", formData.metaTitle);
      formDataToSend.append("image", formData.image);

      await createCategory(formDataToSend, localStorage.getItem("token"));

      // Reset form after submission
      setFormData({
        title: "",
        metaDescription: "",
        metaTitle: "",
        image: null,
      });

      // Show success alert
      setAlertMessage("Category created successfully!");
      setAlertType("success");

      // Hide loading state
      setLoading(false);

      // Reload the page after a short delay
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      console.error("Error submitting form:", error);

      // Show error alert
      setAlertMessage("Error submitting form. Please try again.");
      setAlertType("danger");

      // Hide loading state
      setLoading(false);
    }
  };

  return (
    <div className="d-flex" style={{ background: "#333" }}>
      <SideNavigationBar />
      <div className="container">
        <div className="text-center">
          <div className="card mb-3 shodow p-2 rounded-pill mt-5 border-2">
            <h1>
              Create Category <i class="fa-solid fa-circle-plus"></i>
            </h1>
          </div>
          <button className="btn mb-2   btn-dark shadow rounded-pill text-light">
            <a
              href="/admin/categories"
              style={{ textDecoration: "none" }}
              className="text-light"
            >
              <i class="fa-solid fa-left-long"></i> Back To Categories
            </a>
          </button>
        </div>
        {loading && (
          <div className="alert alert-info" role="alert">
            Submitting form, please wait...
          </div>
        )}
        {alertMessage && !loading && (
          <div className={`alert alert-${alertType}`} role="alert">
            {alertMessage}
          </div>
        )}
        <div>
          <form className="p-4 card mt-3 shadow" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                aria-describedby="title"
                name="title"
                placeholder="Enter Title Here"
                onChange={handleOnChange}
                value={formData.title}
              />
            </div>
            {/*  Meta Description */}
            {/* <div className="mb-3">
              <label htmlFor="metaDescription" className="form-label">
                Meta Description
              </label>
              <textarea
                type="text"
                className="form-control"
                id="metaDescription"
                name="metaDescription"
                placeholder="Enter Meta Description"
                onChange={handleOnChange}
                value={formData.metaDescription}
              />
            </div> */}

            {/*  Meta Title */}
            {/* <div className="mb-3">
              <label htmlFor="metaTitle" className="form-label">
                Meta Title
              </label>
              <input
                type="text"
                className="form-control"
                id="metaTitle"
                aria-describedby="metaTitle"
                name="metaTitle"
                placeholder="Enter Meta Title"
                onChange={handleOnChange}
                value={formData.metaTitle}
              />
            </div> */}

            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Image
              </label>
              <input
                type="file"
                className="form-control"
                id="image"
                aria-describedby="image"
                name="image"
                onChange={handleOnChange}
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-dark shadow rounded-pill btn-wrap"
                disabled={loading}
              >
                Create Category Now{" "}
                <i class="fa-solid fa-lg fa-circle-plus mx-1"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateCategory;
