import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllState = async (page, pageSize) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token is not available in localStorage");
    }

    // Make API request with pagination parameters
    const response = await axios.get(
      `${BASE_URL}/admin/locations/get-all-state`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      }
    );

    // Return data from the response
    return response.data;
  } catch (error) {
    console.error("Error fetching the State Data:", error);
    throw error;
  }
};

const getAllDistrictusingState = async (state) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token is not available in localStorage");
    }

    const response = await fetch(`${BASE_URL}/admin/locations/state/${state}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error ${response.status}: ${errorData.message}`);
    }
    const data = await response.json();
     
    return data;
  } catch (error) {
    console.error("Error fetching the District Data:", error);
    throw error;
  }
};

const sendLocation = async (formData) => {
   

  try {
    const token = localStorage.getItem("token");

    const response = await fetch(`${BASE_URL}/admin/locations/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Request failed with status ${response.status}: ${errorMessage}`
      );
    }

    const responseData = await response.json();
     
    return responseData;
  } catch (error) {
    console.error("Something went wrong....", error);
    throw error;
  }
};

const updateLocation = async (id, formData, token) => {
  try {
     

    const myFormData = new FormData();

    // Append each key-value pair to formData
    Object.keys(formData).forEach((key) => {
      if (key === "stateImage" && formData[key]) {
        // If the key is "stateImage" and it exists in the formData and is not null
        myFormData.append("stateImage", formData[key]);
      } else {
        myFormData.append(key, formData[key]);
      }
    });

    const response = await fetch(`${BASE_URL}/admin/locations/update/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: myFormData,
    });

    if (!response.ok) {
      throw new Error(`Failed to update location: ${response.statusText}`);
    }

    const data = await response.json();
     
    return data;
  } catch (error) {
    console.error("Error updating location:", error);
    throw error;
  }
};

const getLocationById = async (id, token) => {
   

  try {
    if (!token) {
      throw new Error("Token is not available in localStorage");
    }

    const response = await axios.get(
      `${BASE_URL}/admin/locations/get-location/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

     

    // if (!response.ok) {
    //   const errorData = await response.json();
    //   throw new Error(`Error ${response.status}: ${errorData.message}`);
    // }

    const data2 = await response.data;
    return data2;
  } catch (error) {
    console.error("Error fetching location by ID:", error);
    throw error;
  }
};

const deleteLocation = async (id, token) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/locations/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Something went wrong during deleting.....");
    throw error;
  }
};

export {
  getAllState,
  getAllDistrictusingState,
  sendLocation,
  updateLocation,
  getLocationById,
  deleteLocation,
};
