import React from "react";
import "../CSS/AdminDashboard.css";
import { useLocation } from "react-router-dom";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

/**
 *  Auther    : Nihal Sherkar
 *  Date      : 29 may 2024
 *  Description : It contain all chart
 */

const AdminDashboard = () => {
  const location = useLocation();
  const pageName =
    location.pathname === "/admin-dashboard" ? "Dashboard" : "Page Name";

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="content" style={{ display: "flex" }}>
        <div className="container ">
          <p className="text-danger mt-3 mx-2">
            <strong>
              <p>
                {pageName} <i className="fa-solid fa-arrow-right"></i>{" "}
              </p>
            </strong>
          </p>
          <div className="ms-5">
            {/* ALL small Four Cards */}
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex flex-wrap  ">
                  {/* Card-1 */}
                  <div className="col-lg-3 col-md-3 col-sm-3 my-3 shadow ">
                    <div className="card card-1 common-card">
                      {/* Inactive List Link */}
                      <a href="/inactiveList">
                        <div className="d-flex ">
                          <div className="col-lg-7 col-md-8 col-sm-8 text-center mx-3">
                            <strong className="card-digit">10000</strong>
                            <p>Inactivated Listing</p>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 text-center">
                            <div className="mx-3 justify-content-center align-item-center p-3">
                              <i className="fa-solid fa-2xl fa-rectangle-list float-end text-light mt-4 faIcon"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* Card-2 */}
                  <div className="col-lg-3 col-md-3  col-sm-3 my-3 mx-3 shadow">
                    <div className="card card-2 common-card">
                      <a href="/activatedList">
                        <div className="d-flex p-3">
                          <div className=" col-lg-7 col-md-8 col-sm-8 mx-3  text-center mx-3 mt-2">
                            <strong className="card-digit">10000</strong>
                            <p>Activated Listing</p>
                          </div>
                          <div className="col-lg-4   col-md-4 col-sm-4 text-center p-3">
                            <div className=" justify-content-center align-item-center ">
                              <i className="fa-regular fa-circle-check fa-2xl check-icon float-end  "></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* Card-3 */}
                  <div className="col-lg-3 col-md-3  col-sm-3 shadow   my-3 mx-3">
                    <div className="card card-3 common-card">
                      <a href="/user">
                        <div className="d-flex p-3">
                          <div className=" col-lg-6 col-md-6 col-sm-6   text-center mx-3">
                            <strong className="card-digit">600</strong>
                            <p>User</p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6  text-center">
                            <div className="mx-3 justify-content-center align-item-center p-3 float-end ">
                              <i className="fa-regular fa-circle-user fa-2xl faIcon mt-4 text-light"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* Card-4 */}
                  <div className="col-lg-3 col-md-3  col-sm-3 my-3 mx-3 shadow">
                    <div className="card card-4 common-card">
                      <a href="/installed-countries">
                        <div className="d-flex p-3">
                          <div className=" col-lg-6 col-md-6 col-sm-6 text-center mx-3">
                            <h3 className="mt-1">1 </h3>
                            <p>Installed Countries</p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6  text-center">
                            <div className="mx-3 justify-content-center float-end align-item-center p-3">
                              <i className="fa-solid fa-globe  fa-2xl mt-4"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Upper 2 Cards */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex">
                <div className="col-lg-6 col-md-6 col-sm-6 mx-2 bg-dark">
                  <div className="card my-card  shadow ">
                    <div className="row">
                      <div className="d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2  ">
                          <strong className="text-start text-dark mx-2 ">
                            <span
                              className="mx-1"
                              style={{
                                border: "1px solid blue",
                                borderRadius: "100%",
                                width: "50px",
                              }}
                            ></span>
                            Listing Status
                          </strong>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2 ">
                          <p className="float-end   ">
                            <div className="d-flex">
                              <div className="col-lg-3 col-md-3 col-sm-3 d-flex mx-4  ">
                                <strong className="text-dark d-flex">
                                  <p className="blue-circle mt-1 mx-1"></p>{" "}
                                  Activated
                                </strong>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 d-flex mx-4  ">
                                <strong className="text-dark d-flex">
                                  <p className="blue-circle2 mt-1 mx-1"></p>{" "}
                                  Inactivated
                                </strong>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mx-1 bg-dark">
                  <div className="card my-card  shadow ">
                    <div className="row">
                      <div className="d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2  ">
                          <strong className="text-start text-dark mx-2 ">
                            <span
                              className="mx-1"
                              style={{
                                border: "1px solid blue",
                                borderRadius: "100%",
                                width: "50px",
                              }}
                            ></span>
                            User Stats
                          </strong>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6  ">
                          <p className="float-end   ">
                            <div className="d-flex">
                              <div className="col-lg-3 col-md-3 col-sm-3 d-flex mx-4 mt-2 ">
                                <strong className="text-dark d-flex">
                                  <p className="blue-circle mt-1 mx-1"></p>{" "}
                                  Activated
                                </strong>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 d-flex mx-4 mt-2 ">
                                <strong className="text-dark d-flex">
                                  <p className="blue-circle2 mt-1 mx-1"></p>{" "}
                                  Inactivated
                                </strong>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Upper 2 Cards ends */}
            {/* Lpower 2 Cards */}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-5">
              <div className="d-flex">
                <div className="col-lg-6 col-md-6 col-sm-6 mx-2 bg-dark">
                  <div className="card my-card  shadow ">
                    <div className="row">
                      <div className="d-flex">
                        {/* Card 3 Start here */}
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2  ">
                          <strong className="text-start text-dark mx-2 ">
                            <span
                              className="mx-1"
                              style={{
                                border: "1px solid blue",
                                borderRadius: "100%",
                                width: "50px",
                              }}
                            ></span>
                            Latest Listing
                          </strong>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2 ">
                          <p className="float-end">
                            <div className="d-flex mx-2">
                              <div className="col-lg-6 col-md-6 col-sm-6 d-flex mx-2 ">
                                <button className="btn text-dark p-2 btn-sm post-Listing shadow">
                                  <strong>Post New Listing</strong>
                                </button>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 d-flex mx-2">
                                <button className="btn text-dark  btn-sm p-2 view-all-list shadow">
                                  <strong>View All Listing</strong>
                                </button>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Card 4 Start here */}
                <div className="col-lg-6 col-md-6 col-sm-6 mx-2 bg-dark ">
                  <div className="card my-card  shadow ">
                    <div className="row">
                      <div className="d-flex">
                        {/* Card 3 Start here */}
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2  ">
                          <strong className="text-start text-dark mx-2 ">
                            <span
                              className="mx-1"
                              style={{
                                border: "1px solid blue",
                                borderRadius: "100%",
                                width: "50px",
                              }}
                            ></span>
                            Latest User
                          </strong>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-2 ">
                          <p className="float-end">
                            <div className="d-flex  ">
                              <div className="col-lg-12 col-md-12 col-sm-12 d-flex mx-2">
                                <button className="btn text-dark  btn-sm p-2 view-all-list shadow ">
                                  <strong>View All Listing</strong>
                                </button>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Lower 2 Cards ends */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
