import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../CSS/Blogs.css";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";

const AdminBlogs = () => {
  const location = useLocation();
  const pageName = location.pathname === "/blogs" ? "Blog Post" : "Page Name";
  const searchParams = new URLSearchParams(location.search);
  const parent = searchParams.get("parent") || "Undefined";

  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState({
    id: null,
    title: "",
    description: "",
    comments: [],
  });

  const [currentComment, setCurrentComment] = useState({
    id: null,
    text: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;

  const [modalType, setModalType] = useState("add");

  const [setIsEditingComment] = useState(false);

  const [commentToEdit, setCommentToEdit] = useState("");
  const [commentIndexToEdit, setCommentIndexToEdit] = useState(null);

  useEffect(() => {
    setPosts([
      {
        id: 1,
        title: "post1",
        description: "Description 1",
        comments: ["Very Nice Page"],
      },
      { id: 2, title: "post-2", description: "Description 2", comments: [] },
      { id: 3, title: "post-3", description: "Description 3", comments: [] },
      { id: 4, title: "post-4", description: "Description 4", comments: [] },
      { id: 5, title: "post-5", description: "Description 5", comments: [] },
    ]);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPost({ ...currentPost, [name]: value });
  };

  const handleSave = () => {
    if (modalType === "add") {
      setPosts([...posts, { ...currentPost, id: posts.length + 1 }]);
    } else {
      setPosts(
        posts.map((post) => (post.id === currentPost.id ? currentPost : post))
      );
    }
    const modal = new window.bootstrap.Modal(
      document.getElementById("postModal")
    );
    modal.hide();
  };

  const handleDelete = (id) => {
    if (id) {
      setPosts(posts.filter((post) => post.id !== id));
      alert("Data Deleted Successfully !!!");
    }
  };

  const handleShowModal = (type, post) => {
    setModalType(type);

    if (type === "edit") {
      setCurrentPost(post);
    } else {
      setCurrentPost({ id: null, title: "", description: "", comments: [] });
    }

    const modal = new window.bootstrap.Modal(
      document.getElementById("postModal")
    );
    modal.show();
  };

  const addComment = () => {
    const updatedComments = [...currentPost.comments, currentComment.text];
    setCurrentPost({ ...currentPost, comments: updatedComments });
    setCurrentComment({ id: null, text: "" });
  };

  // SHOW EDIT COMMENT MODAL
  const handleShowEditCommentModal = (comment, index) => {
    setCommentToEdit(comment);
    setCommentIndexToEdit(index);
    setIsEditingComment(true);
    const modal = new window.bootstrap.Modal(
      document.getElementById("editCommentModal")
    );
    modal.show();
  };

  const handleEditCommentChange = (e) => {
    setCommentToEdit(e.target.value);
  };

  const updateComment = () => {
    const updatedPosts = posts.map((post) =>
      post.id === currentPost.id
        ? {
            ...post,
            comments: post.comments.map((comment, index) =>
              index === commentIndexToEdit ? commentToEdit : comment
            ),
          }
        : post
    );

    setPosts(updatedPosts);
    setIsEditingComment(false);
    const modal = new window.bootstrap.Modal(
      document.getElementById("editCommentModal")
    );

    modal.hide();
  };

  const deleteComment = (commentText) => {
    const updatedComments = currentPost.comments.filter(
      (comment) => comment !== commentText
    );
    setCurrentPost({ ...currentPost, comments: updatedComments });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="d-flex">
      <SideNavigationBar />
      <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <p className="text-danger mt-3 mx-2">
          <strong>
            <p>
              {parent} <i className="fa-solid fa-arrow-right"></i> {pageName}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </p>
          </strong>
        </p>
        <div className="text-center">
          <h1 className="add-post">
            <i className="fa-solid fa-screwdriver-wrench"></i> Blog Admin
          </h1>

          <button
            className="btn btn-dark btn-sm   rounded-pill mb-2 p-2   "
            onClick={() => handleShowModal("add")}
          >
            <i className="fa-solid fa-plus  "></i> Add Post
          </button>
        </div>

        <div className="container mt-2">
          <div className="table-responsive card p-5">
            <table className="table shadow table-bordered border mt-3">
              <caption>
                {" "}
                <strong className=" mx-1">List of Blogs</strong>
              </caption>
              <thead className="table-dark">
                <tr className="text-center">
                  <th scope="col">
                    <i className="fa-regular fa-id-badge"></i> Id
                  </th>
                  <th scope="col">
                    <i className="fa-solid fa-bars-progress"></i> Title
                  </th>
                  <th scope="col">
                    <i className="fa-solid fa-list-check"></i> Description
                  </th>
                  <th scope="col">
                    <i className="fa-solid fa-list-check"></i> Photos
                  </th>
                  <th scope="col">
                    <i className="fa-solid fa-list-check"></i> Comments
                  </th>
                  <th scope="col">
                    <i className="fa-solid fa-circle-exclamation"></i> Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-center ">
                {currentPosts.map((post) => (
                  <tr key={post.id} className="fw-bold">
                    <td>{post.id}</td>
                    <td>{post.title}</td>

                    <td className="">{post.description}</td>
                    <td>{post.photo}</td>
                    <td>
                      <ul className="list-group list-group-flush">
                        {post.comments.map((comment, index) => (
                          <li key={index} className="list-group-item">
                            {comment}{" "}
                            <button
                              className="btn btn-sm btn-warning mx-2 rounded-pill"
                              onClick={() =>
                                handleShowEditCommentModal(comment, index)
                              }
                            >
                              <i className="fa-regular fa-pen-to-square"></i>{" "}
                              Edit
                            </button>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="d-flex justify-content-center">
                      <button
                        className="btn btn-sm btn-warning rounded-pill"
                        onClick={() => handleShowModal("edit", post)}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> Edit
                      </button>
                      <button
                        className="btn btn-sm btn-danger rounded-pill"
                        onClick={() => handleDelete(post.id)}
                      >
                        <i className="fa-solid fa-trash   mt-1"></i> Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex justify-content-center mt-3">
              <nav>
                <ul className="pagination">
                  {Array.from({
                    length: Math.ceil(posts.length / postsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index + 1}
                      className={`page-item ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => paginate(index + 1)}
                        className="page-link"
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* POST MODAL */}
        <div
          className="modal fade"
          id="postModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {modalType === "add" ? "Add Post" : "Edit Post"}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="post-title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="post-title"
                    name="title"
                    value={currentPost.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="post-description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="post-description"
                    name="description"
                    value={currentPost.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="post-comment" className="form-label">
                    Comment
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="post-comment"
                    name="comment"
                    value={currentComment.text}
                    onChange={(e) =>
                      setCurrentComment({ id: null, text: e.target.value })
                    }
                  />
                </div>
                <button className="btn btn-primary" onClick={addComment}>
                  Add Comment
                </button>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* EDIT COMMENT MODAL */}
        <div
          className="modal fade"
          id="editCommentModal"
          tabIndex="-1"
          aria-labelledby="editCommentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="editCommentModalLabel">
                  Edit Comment
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="edit-comment" className="form-label">
                    Comment
                  </label>
                  <textarea
                    className="form-control"
                    id="edit-comment"
                    name="edit-comment"
                    value={commentToEdit}
                    onChange={handleEditCommentChange}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={updateComment}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBlogs;
