import React, { useEffect, useState } from "react";
import "../../CSS/CheckOut.css";
import {
  getSellerCheckoutResponse,
  promoteSellerPost,
} from "../../Services/TopupPage";
import { useNavigate, useParams } from "react-router-dom";

const CheckOut = () => {
  const [checkoutData, setCheckoutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const params = useParams();
  const { adId, packageId, slotId } = params;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSellerCheckoutResponse(adId, packageId, slotId);
        setCheckoutData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [adId, packageId, slotId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!checkoutData) {
    return <div>No data available</div>;
  }

  const handleAddProceed = async () => {
    try {
      const sellerData = await promoteSellerPost(adId, packageId, slotId);
      setCheckoutData(sellerData);

      if (sellerData.Success === true) {
        navigate("/myads");
      }
    } catch (error) {
      console.log("Error handleAddProceed:  ", error);
    }
  };

  const handleAddMoney = () => {
    navigate("/select-amount");
  };

  return (
    <div>
      <div className="checkout_container">
        <div className="checkout_header">Checkout</div>
        <div className="check_card">
          <img className="check_img" src={checkoutData.image} alt="Image" />
          <div className="check_right">
            <span className="check_head">{checkoutData.title}</span>
            <div>
              <span className="check_adId">
                AD ID:{" "}
                <span className="check_common check_packName">
                  {checkoutData.adId}
                </span>
              </span>
            </div>
            <div>
              <span className="check_adId">
                Slot:
                <span className=" mx-2 check_common check_packName">
                  {checkoutData.slot}
                </span>
              </span>
            </div>
            <div className=" ">
              <div className=" ">
                <span className="check_package">
                  <span className="digit">
                    Package Amount:
                    <span className="check_common check_packName   mx-2">
                      <i className="fa-solid fa-xs fa-indian-rupee-sign"></i>
                      {checkoutData.packageAmount}
                    </span>
                  </span>
                </span>
              </div>

              <div className=" ">
                <span className="check_package">
                  Package:
                  <span className="check_common check_packName mx-2">
                    {checkoutData.packageName}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="  wallet_card">
            <div className="card-body">
              <div className="text-center mb-2">
                <span className="mx-4 check_wallet_title">Wallet</span>
              </div>
              <div>
                <span className="mx-4 avl_wal_head">
                  Available:
                  <span className="avl_bal">
                    {checkoutData.availableBalance}
                  </span>
                </span>
              </div>
              <span className="mx-4 avl_wal_head">
                Usable:
                <span className="usable_bal">{checkoutData.usableBalance}</span>
              </span>
              <span className="mx-3 avl_wal_head">
                Remaining:{" "}
                <span className="remain_bal">
                  {checkoutData.remainingBalance}
                </span>
              </span>
              <div>
                <span className="mx-3 check_msg">{checkoutData.message}</span>
              </div>
            </div>
            <div className="check_condition_btn">
              {checkoutData.canProceed ? (
                <button className="proceed_btn" onClick={handleAddProceed}>
                  Proceed
                </button>
              ) : (
                <button className="add_money_btn" onClick={handleAddMoney}>
                  Add Money
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
