const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllPayments = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/admin/transaction/get-all-payments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`${response.description}`);
    }

    const data = await response.json();
     
    return data;
  } catch (error) {
    console.error(error.description);
    throw error;
  }
};

const getAllPaymentStatuses = async (token) => {
    try {
      const response = await fetch(`${BASE_URL}/admin/transaction/get-all-payment-status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`${response.description}`);
      }
  
      const data = await response.json();
       
      return data;
    } catch (error) {
      console.error(error.description);
      throw error;
    }
  };

  const updatePaymentStatus = async (token, id, status) => {
    try {
      const response = await fetch(`${BASE_URL}/admin/transaction/update-payment-status/${id}/${status}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`${response.description}`);
      }
  
      const data = await response.json();
       
      return data;
    } catch (error) {
      console.error(error.description);
      throw error;
    }
  };

export { getAllPayments, getAllPaymentStatuses, updatePaymentStatus };