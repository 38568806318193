import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const sendDescription = async (description, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/dashboard/add`,
      description,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
     
    return response.data;
  } catch (error) {
    console.error("Error to Sending Description Data !!!!", error);
    throw error;
  }
};

const getDescription=async(token)=>{
    try {
      const response=await fetch(`${BASE_URL}/admin/dashboard/get-first`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        throw new Error(`Failed to fetch categories: ${response.statusText}`);
      }

      const data=await response.json()
       
      return data;
    } catch (error) {
      console.error("Error fetching the Description:", error);
    throw error;
    }
}




const updateDescription = async (id, description, token) => {
  try {
    const response = await axios.put(`${BASE_URL}/admin/dashboard/update/${id}`, description, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.data) {
      throw new Error(`Failed to update Description: ${response.statusText}`);
    }

    // Assuming backend returns updated category data
     
    return response.data;
  } catch (error) {
    console.error("Error updating Description:", error);
    throw error;
  }
};


const deleteDescription=async (id,token)=>{
  try {
     
  } catch (error) {
    
  }
     
}
 








export { sendDescription,getDescription,updateDescription };
