import React, { useEffect, useState } from "react";
import { getDescription } from "../Services/DescriptionService";
import "./MyApp.css";
import SideNavigationBar from "./SideNavigationBar";
import { Link } from "react-router-dom";
import "../CSS/ListDescription.css";

const AdminListDescription = () => {
  const [description, setDescription] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const data = await getDescription(token);

        // Assuming data is an object with properties like id, h1, h2, description
        setDescription(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="loader-text text-danger">
            <h3> Please wait, Description data is loading...</h3>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleUpdate = (id) => {
    try {
      window.location.href = `/admin/update-Description/${id}`;
    } catch (error) {}
  };

  const handleDelete = async (id) => {
    try {
      // Add delete functionality here
    } catch (error) {
      console.error("Error deleting description:", error);
    }
  };

  return (
    <div className="d-flex" style={{ background: "#333" }}>
      <SideNavigationBar />

      <div
        className="container justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="card mb-3 shadow p-2 rounded-pill my-1 border-2 text-center mt-5">
          <h1>Description</h1>
        </div>
        <div className="text-center">
          <button className="btn btn-dark btn-sm rounded-pill mx-2 mb-2 p-2">
            <Link
              className="text-light shadow"
              style={{ textDecoration: "none" }}
              to={"/admin/description"}
            >
              <i className="mx-2 fa-solid fa-chevron-left"></i>Back To Add
              Description
            </Link>
          </button>
        </div>
        <div>
          <div className="  p-5  ">
            <div>
              {description ? (
                <div className="card container shadow mb-4">
                  <div className="card-body">
                    <div className="d-flex">
                      <h5 className="card-title text-uppercase">
                        <strong>H1 Tag:</strong> {description.headingH1}
                      </h5>
                      <h5 className="card-subtitle mb-2    text-muted mx-5">
                        <strong>H2 Tag:</strong> {description.headingH2}
                      </h5>
                    </div>
                    <h5 className="card-text">
                      <strong>Description:</strong> {description.description}
                    </h5>
                    <div className="d-flex float-end ">
                      <button
                        className="btn btn-warning btn-sm rounded-pill text-dark shadow"
                        onClick={() => handleUpdate(description.id)}
                      >
                        <strong>
                          Update{" "}
                          <i className="fa-regular fa-pen-to-square mx-1"></i>
                        </strong>
                      </button>
                      <button
                        className="btn btn-danger btn-sm rounded-pill shadow"
                        onClick={() => handleDelete(description.id)}
                      >
                        <strong>
                          Delete{" "}
                          <i className="fa-regular fa-trash-can mx-1"></i>
                        </strong>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>No description available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminListDescription;
